import React, { useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Icon from "react-native-vector-icons/Ionicons";
import colors from "../styles/colors";
import { Feather } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";

const BottomNavigationBar = () => {
  const navigation = useNavigation();
  const [selectedTab, setSelectedTab] = useState("HomeScreen");

  const navigateToHomeScreen = () => {
    navigation.navigate("HomeScreen");
    setSelectedTab("HomeScreen");
  };

  const navigateToFavoritesScreen = () => {
    navigation.navigate("FavoritesScreen");
    setSelectedTab("FavoritesScreen");
  };

  const navigateToProfile = () => {
    navigation.navigate("Profile");
    setSelectedTab("Profile");
  };

  const isTabSelected = (tabName) => {
    return tabName === selectedTab;
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[styles.tab, isTabSelected("HomeScreen") && styles.selectedTab]}
        onPress={navigateToHomeScreen}
      >
        <Feather
          name="home"
          size={20}
          color={isTabSelected("HomeScreen") ? "white" : "white"}
        />
        <Text
          style={[
            styles.tabText,
            isTabSelected("HomeScreen") && styles.selectedTabText,
          ]}
        >
          Home
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.tab,
          isTabSelected("FavoritesScreen") && styles.selectedTab,
        ]}
        onPress={navigateToFavoritesScreen}
      >
        <MaterialIcons
          name="favorite"
          size={20}
          color={isTabSelected("FavoritesScreen") ? "white" : "white"}
        />
        <Text
          style={[
            styles.tabText,
            isTabSelected("FavoritesScreen") && styles.selectedTabText,
          ]}
        >
          Favorites
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.tab, isTabSelected("Profile") && styles.selectedTab]}
        onPress={navigateToProfile}
      >
        <Feather
          name="user"
          size={20}
          color={isTabSelected("Profile") ? "white" : "white"}
        />
        <Text
          style={[
            styles.tabText,
            isTabSelected("Profile") && styles.selectedTabText,
          ]}
        >
          Profile
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: colors.primaryColorVariant,
    height: 60,
  },
  tab: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 10,
  },
  tabText: {
    fontSize: 14,
    color: "white",
  },
  selectedTab: {
    backgroundColor: colors.primaryColor,
  },
  selectedTabText: {
    color: "white",
  },
});

export default BottomNavigationBar;
