import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  StyleSheet,
  Platform,
} from "react-native";
import axios from "axios";
import { useRoute, useNavigation } from "@react-navigation/native";
import Icon from "react-native-vector-icons/FontAwesome";
import CustomHeader from "../../../shared/CustomHeader";
import DateTimePicker from "@react-native-community/datetimepicker";
import DatePicker from "react-datepicker";
import colors from "../../../styles/colors";
import { BASE_URL } from "../../../config";

const dayImages = [
  require("../../../assets/images/meal/2.png"),
  require("../../../assets/images/meal/3.png"),
  require("../../../assets/images/meal/6.png"),
  require("../../../assets/images/meal/9.png"),
  require("../../../assets/images/meal/11.png"),
];

const MealCalendar = ({ route }) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [currentDate, setCurrentDate] = useState(
    route.params?.startDate || new Date()
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [mealData, setMealData] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const navigation = useNavigation();

  const selectedWeek = route.params?.calendarWeek || "WEEK1";
  const [startDate, setStartDate] = useState(
    route.params?.startDate || new Date()
  );
  const [endDate, setEndDate] = useState(route.params?.endDate || new Date());

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
    fetchMealData(startDate, endDate);
  }, []);

  const handlePrevWeek = async () => {
    const prevWeek = new Date(currentDate);
    prevWeek.setDate(prevWeek.getDate() - 7);
    console.log("Previous week");
    await fetchMealData(prevWeek, prevWeek);
    setCurrentDate(prevWeek);
    setStartDate(prevWeek);

    const prevWeekEndDate = new Date(endDate); // Use the current endDate
    prevWeekEndDate.setDate(prevWeekEndDate.getDate() - 7);
    console.log("Start Date: ", startDate);
    setEndDate(prevWeekEndDate);
  };

  const handleNextWeek = async () => {
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(nextWeek.getDate() + 7);
    await fetchMealData(nextWeek, nextWeek);
    setCurrentDate(nextWeek);
    setStartDate(nextWeek);
    const nextWeekEndDate = new Date(endDate); // Use the current endDate
    nextWeekEndDate.setDate(nextWeekEndDate.getDate() + 7);
    setEndDate(nextWeekEndDate);
  };

  const handleDateChange = async (date) => {
    setShowDatePicker(Platform.OS === "ios" || Platform.OS === "android");
    if (date) {
      setSelectedDate(date);
      setCurrentDate(date);
      await fetchMealData(date, date);
    }
  };

  const fetchMealData = async (fromDate, toDate) => {
    try {
      const to = new Date(fromDate);
      to.setDate(to.getDate() + 7);

      const response = await axios.get(
        `${BASE_URL}/provider-meal/get-user-meals?user_id=103&from_date=${ // TODO: Security Risk Change ASAP!!
          fromDate.toISOString().split("T")[0]
        }&to_date=${to.toISOString().split("T")[0]}`
      );

      if (response.data.success) {
        setMealData(response.data.data);
      } else {
        console.error("Failed to fetch meal data.");
      }
    } catch (error) {
      console.error("An error occurred while fetching meal data:", error);
    }
  };

  const renderDays = () => {
    const currentDayIndex = currentDate.getDay();
    const getMonday = () => {
      const today = currentDate;
      const dayOfWeek = currentDate.getDay();
      const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
      return new Date(today.setDate(diff));
    };

    const startOfWeek = getMonday();

    const dynamicDaysOfWeek = Array.from({ length: 5 }, (_, index) => {
      const dayIndex = (startOfWeek.getDay() + index) % 7;
      return daysOfWeek[dayIndex];
    });

    return dynamicDaysOfWeek.map((day, index) => {
      const dayDate = new Date(startOfWeek);
      dayDate.setDate(dayDate.getDate() + index);

      const formattedDate = dayDate.toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const mealsForDay = mealData.filter(
        (meal) => meal.meals.day_of_the_week.toLowerCase() === day.toLowerCase()
      );

      return (
        <View key={index} style={styles.cardStyle}>
          <Text style={styles.dayStyle}>{day}</Text>
          <Text style={styles.dateStyle}>{formattedDate}</Text>
          {mealsForDay.map((meal, mealIndex) => (
            <View key={mealIndex}>
              <Image source={dayImages[index]} style={styles.imageStyle} />
              <Text style={styles.mealStyle}>{meal.meals.meal_name}</Text>
              <Text>{meal.meals.sides.join(", ")}</Text>
            </View>
          ))}
        </View>
      );
    });
  };

  return (
    <View style={styles.container}>
      <CustomHeader />
      <Text style={styles.headerText}>
        Meal Calendar{" "}
        <Icon name="calendar" size={24} color={colors.tertiaryColor} />
      </Text>
      <Text style={styles.titleStyle}>
        ({`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
        )
      </Text>
      <View style={styles.table}>
        <View style={styles.cardContainerStyle}>
          <View style={styles.weekNavStyle}>
            <TouchableOpacity
              style={styles.buttonStyle}
              onPress={handlePrevWeek}
            >
              <Text style={styles.buttonTextStyle}>Previous Week</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.buttonStyle}
              onPress={handleNextWeek}
            >
              <Text style={styles.buttonTextStyle}>Next Week</Text>
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: "row" }}>{renderDays()}</View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  cardContainerStyle: {
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
  },
  headerText: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
    color: colors.primaryColor,
    marginTop: 10,
  },
  weekNavStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 20,
  },
  imageStyle: {
    width: 150,
    height: 120,
    alignSelf: "center",
    margin: 3,
  },
  cardStyle: {
    width: 200,
    padding: 20,
    textAlign: "center",
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    padding: 16,
    margin: 8,
    backgroundColor: "white",
    borderColor: "#007AFF",
    borderRadius: 8,
    elevation: 5,
  },
  dayStyle: {
    fontSize: 16,
    marginBottom: 3,
    color: colors.primaryColor,
    fontWeight: "bold",
    textAlign: "center",
  },

  titleStyle: {
    fontSize: 16,
    color: colors.secondaryColor,
    textAlign: "center",
  },
  mealStyle: {
    fontSize: 16,
    marginBottom: 5,
    fontWeight: "bold",
    marginTop: 7,
  },
  dateStyle: {
    textAlign: "center",
    fontSize: 14,
    color: "#777",
  },
  buttonStyle: {
    backgroundColor: colors.primaryColorVariant,
    padding: 7,
    marginLeft: 5,
    borderRadius: 5,
    alignItems: "center",
  },
  buttonTextStyle: {
    color: "white",
  },
  table: {
    marginTop: 8,
    marginHorizontal: 20,
    backgroundColor: "white",
    borderRadius: 8,
    elevation: 5,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    padding: 16,
  },
  datePickerContainer: {
    backgroundColor: "#f0f0f0",
    padding: 10,
    borderRadius: 8,
    zIndex: 1,
  },
  datePickerButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 8,
    paddingHorizontal: 10,
    height: 40,
  },
  datePickerButtonText: {
    flex: 1,
  },
  calendarIcon: {
    color: colors.tertiaryColor,
    marginLeft: 5,
  },
});

export default MealCalendar;

/* <View style={styles.datePickerContainer}>
            {Platform.OS === "ios" && showDatePicker && (
              <DateTimePicker
                testID="dateTimePicker"
                value={currentDate}
                mode="date"
                is24Hour={true}
                display="calendar"
                onChange={handleDateChange}
              />
            )}
            {Platform.OS === "android" && showDatePicker && (
              <DateTimePicker
                testID="dateTimePicker"
                value={currentDate}
                mode="date"
                is24Hour={true}
                display="spinner"
                onChange={handleDateChange}
              />
            )}
          </View>  */
