import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

const ButtonsContainer = ({ handleButtonPress, styles }) => {
  return (
    <View style={styles.buttonsContainer}>
      {/* Button for HOT MEALS with onPress handler */}
      <TouchableOpacity style={styles.buttonHotMeals} onPress={() => handleButtonPress('HOT MEALS')}>
        <Text style={styles.buttonText}>HOT MEALS</Text>
      </TouchableOpacity>
      {/* Button for COLD BAGS with onPress handler */}
      <TouchableOpacity style={styles.buttonColdBags} onPress={() => handleButtonPress('COLD BAGS')}>
        <Text style={styles.buttonText}>COLD BAGS</Text>
      </TouchableOpacity>
      {/* Button for SPECIAL with onPress handler */}
      <TouchableOpacity style={styles.buttonSpecial} onPress={() => handleButtonPress('SPECIAL')}>
        <Text style={styles.buttonText}>SPECIAL</Text>
      </TouchableOpacity>
    </View>
  );
};

export default ButtonsContainer;