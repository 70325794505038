
import { View, Platform, StyleSheet, TouchableOpacity, TextInput, Text, Alert,SafeAreaView,ScrollView, } from "react-native";
import React, { useLayoutEffect, useState } from 'react';
import { useNavigation } from "@react-navigation/native";
import axios from 'axios';
import LoginHeader from "../../../shared/LoginHeader";
import {BASE_URL} from '../../../config.js';

TouchableOpacity.defaultProps = { activeOpacity: 0.8 };
const  SignUpView = () => {
    const nav = useNavigation();
    var erString = "";
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [date_of_birth, setBirth] = useState('');
    const [addr_street, setStreet] = useState('');
    const [addr_city, setCity] = useState('');
    const [addr_state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [user_group, setUser_group] = useState('client');

    const registerUser = async (first_name, last_name, email, password, phone, date_of_birth, addr_street, addr_city, addr_state, zipcode) => {
      try {
        const registerAPI = await axios.post(`${BASE_URL}/auth/signup`, {
          first_name, last_name, email, password, phone, date_of_birth, addr_street, addr_city, addr_state, zipcode,user_group,
        });
        if (registerAPI.status === 200) {
          console.log('Registration successful');
          nav.navigate('LoginView');
        } else {
          showAlert('Error:', 'Unable to Register at this Time');
          console.log('Registration failed');
        }
      } catch (error) {
        console.error('Error during registration:', error.message);
      }
    };
   

    const handleRegister = () => {
     
      if(first_name.length === 0)
      {
        erString = erString + " Missing First Name,"
      }
      if(last_name.length === 0)
      {
        erString = erString + " Missing Last Name,"
      }
      if(!validateEmail(email)) {
        erString = erString + " Email,";
      }
      if(password.length < 6) {
  
        erString = erString + " Password,";
      }
      if(!validatePhoneNumber(phone)) {
        erString = erString + " Phone Number,";
      }
      if (!validateDateOfBirth(date_of_birth)) {
        erString = erString + " date format(yyyy-mm-dd,";
      }
      if(password !== confirmPassword)
      {
        erString = erString + " Password do not match";
      }
      if (erString.length > 1)
      {
        showAlert("Invalid "+erString.substring(0,erString.length-1))
        return
      }
      

      registerUser(first_name, last_name, email, password, phone, date_of_birth, addr_street, addr_city, addr_state, zipcode, user_group,);
      console.log('Registration Successful');
      showAlert("Create Account", "Your information has been submitted to Nueva");
    };

    const validateEmail = (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
    const validateDateOfBirth = (date_of_birth) => {
      const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
      return dateFormat.test(date_of_birth);
    };
    const validatePhoneNumber = (phone) => {
      return !isNaN(phone) && phone.length >= 10;

    };
    const showAlert = (title, message) => {
      console.log(Platform.OS)
      if (Platform.OS === 'web')
      {
        alert(title, message);
      }
      else
      {
        Alert.alert(title, message, [{ text: 'OK' }], { cancelable: false });
      }
    };

    const renderLoginHeader = <LoginHeader /> ;

    return (
      <SafeAreaView
      style={{
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      {renderLoginHeader}
      <ScrollView showsVerticalScrollIndicator={true}>
            <View style = {styles.viewStyle}>
              <Text style={styles.titleText}>      Register      </Text>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "First Name" 
              value = {first_name}
              onChangeText={(text) => setFirstName(text)}
              style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "Last Name" 
              value = {last_name}
              onChangeText={(text) => setLastName(text)}
              style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "Email"
              value= {email}
              onChangeText={(text) => setEmail(text)}
              style={styles.input}
              keyboardType="email-address"/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "Password" 
                value = {password}
                onChangeText={(text) => setPassword(text)}
                secureTextEntry={true}
                style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "Confirm Password" 
                value = {confirmPassword}
                onChangeText={(text) => setConfirmPassword(text)}
                secureTextEntry={true}
                style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "Phone Number" 
                value = {phone}
                onChangeText={(text) => setPhone(text)}
                style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "Date of Birth (yyyy-mm-dd)" 
              value = {date_of_birth}
              onChangeText={(text) => setBirth(text)}
              style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "Street Address" 
              value = {addr_street}
              onChangeText={(text) => setStreet(text)}
              style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "City" 
              value = {addr_city}
              onChangeText={(text) => setCity(text)}
              style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "State" 
              value = {addr_state}
              onChangeText={(text) => setState(text)}
              style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
              <TextInput placeholder= "Zipcode" 
              value = {zipcode}
              onChangeText={(text) => setZipcode(text)}
              style={styles.input}/>
            </View>
            <View style = {styles.viewStyle}>
      <AppButton title="REGISTER" backgroundColor= "#9f3ac7" onPress={
                handleRegister
            }/>
            </View>
           
      </ScrollView>
      
        </SafeAreaView>
      );
  };
  const AppButton = ({ onPress, title,backgroundColor }) => (
    <TouchableOpacity 
    style={[
        styles.appButtonContainer,
        backgroundColor && { backgroundColor }
      ]}
    >
      <Text style={styles.appButtonText} onPress={onPress} >{title}</Text>
    </TouchableOpacity>
);

  const styles = StyleSheet.create({
    screenContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      padding: 80,
    },
    appButton: {
      padding: 12,
      alignSelf: 'center',
    },
    appButtonContainer: {
      elevation: 8,
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'space-between',
      height: 50,
      width: 200,
      marginTop: 30,
      borderRadius: 12
    },
    appButtonText: {
      fontSize: 18,
      color: "#fff",
      fontWeight: "bold",
      alignSelf: "center",
      textTransform: "uppercase"
    },
    input: {
        backgroundColor: "#fff",
        padding: 10,
        width: "10%",
        marginTop: 15,
        color: "#000",
    },
    viewStyle: {
        flexDirection: 'row', 
        borderBottomColor: '#ccc', 
        paddingBottom: 8,
        justifyContent: "center",
        alignItems: "center",

    },titleText: {
      fontSize: 30,
      fontWeight: 'bold',
      color: "black",
      alignContent : "center", 
      marginTop: 0,
    },
  });
export default SignUpView;