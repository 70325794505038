// Bag title does not include the number
let backColor = "#fff";
import React from "react";
import { View, Text, StyleSheet } from "react-native";

// MealCard displays information for the specific meal type
const MealCard = ({ bagList, bagTitle, backgroundColor }) => {
  let counter = 0;
  // get number of items for this meal type
  for (let item of bagList) {
    counter += item.count;
  }
  return (
    <View style={[styles.card, { backgroundColor }]}>
      <Text style={styles.title}>
        {counter} {bagTitle}
      </Text>
      <View style={styles.listContainer}>
        {bagList.map((item, index) => (
          <View key={index} style={styles.listItem}>
            <Text style={styles.bulletPoint}>•</Text>
            <Text style={styles.itemText}>
              {item.count} {item.name}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: backColor,
    marginBottom: 10,
    padding: 15,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginLeft: "10%",
    maxWidth: "80%",
  },
  title: {
    fontSize: 20,
    letterSpacing: 0.8,
    fontWeight: "500",
    //fontFamily: "Inter-Medium",
    marginBottom: 10,
  },
  listContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    width: "50%", // This ensures 2 items per row
    marginBottom: 5,
    paddingLeft: "3%",
  },
  bulletPoint: {
    marginRight: 5,
  },
  itemText: {
    fontSize: 16,
    letterSpacing: 0.8,
    fontWeight: "500",
    // fontFamily: "Inter-Medium",
  },
});

export default MealCard;
