import React, { useLayoutEffect } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  SafeAreaView,
  ScrollView,
  Platform,
} from "react-native";
import LoginHeader from "../../../shared/LoginHeader";
TouchableOpacity.defaultProps = { activeOpacity: 0.8 };

const VolunteerButtons = () => {
  const nav = useNavigation();
  const route = useRoute();
  const isVolunteer = route.params?.isVolunteer || false;
  console.log({ isVolunteer });

  const isWeb = () => Platform.OS === "web";
  const renderLoginHeader = isWeb() ? <LoginHeader /> : null;

  return (
    <SafeAreaView style={styles.container}>
      {renderLoginHeader}
      <View style={styles.screenContainer}>
        <AppButton
          title="LOGIN"
          backgroundColor="#00A4EF"
          onPress={() => nav.navigate("LoginView", { isVolunteer: true })}
        />
        <Text
          style={{
            textAlign: "center",
            color: "#666",
            marginTop: 20,
            marginBottom: 5,
          }}
        >
          ----Or----
        </Text>
        <AppButton
          title="CREATE NEW ACCOUNT"
          backgroundColor="#CC397B"
          onPress={() => nav.navigate("VolunteerRegister")}
        />
        <Text
          style={{
            textAlign: "center",
            color: "#666",
            marginTop: 20,
            marginBottom: 5,
          }}
        >
          ----Or----
        </Text>
        <AppButton
          title="REFER"
          backgroundColor="#008080"
          onPress={() => nav.navigate("Advocate")}
        />
      </View>
    </SafeAreaView>
  );
};

const AppButton = ({ onPress, title, backgroundColor }) => (
  <TouchableOpacity
    onPress={onPress}
    style={[styles.appButtonContainer, backgroundColor && { backgroundColor }]}
  >
    <Text style={styles.appButtonText}>{title}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  screenContainer: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  appButton: {
    padding: 12,
    alignSelf: "center",
  },
  appButtonContainer: {
    alignSelf: "center",
    elevation: 8,
    //alignItems: 'center',
    justifyContent: "center",
    alignContent: "space-between",
    height: 50,
    width: 200,
    marginBottom: 20,
    borderRadius: 12,
  },
  appButtonText: {
    fontSize: 16,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase",
  },
  image: {
    flex: 1,
    resizeMode: "cover",
  },

  card: {
    width: "50%",
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    paddingTop: 20,
    marginHorizontal: 150,
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 10,
  },
});

export default VolunteerButtons;
