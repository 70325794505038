import React, { useLayoutEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  View,
  Platform,
  StyleSheet,
  TouchableOpacity,
  Text,
  SafeAreaView,
  Alert,
  ScrollView,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import FontIcon from "react-native-vector-icons/FontAwesome5";
import LoginHeader from "../../../shared/LoginHeader";
import { useAuth } from "../../../components/AuthContext";
import VolunteerButtons from "./VolunteerButtons";
TouchableOpacity.defaultProps = { activeOpacity: 0.8 };

const VolunteerChoice = () => {
  const nav = useNavigation();
  const { state } = useAuth();
  const user = state.user;

  const [isMouseOverClient, setMouseOverClient] = useState(false);
  const [isMouseOverVolunteer, setMouseOverVolunteer] = useState(false);

  const handleMouseOverClient = () => {
    setMouseOverClient(true);
  };

  const handleMouseOutClient = () => {
    setMouseOverClient(false);
  };

  const handleMouseOverVolunteer = () => {
    setMouseOverVolunteer(true);
  };

  const handleMouseOutVolunteer = () => {
    setMouseOverVolunteer(false);
  };

  const VolunteerButton = () => {
    nav.navigate("VolunteerButtons", { isVolunteer: true });
    console.log("Volunteer button pressed");
  };

  const ClientButton = () => {
    nav.navigate("LoginButtons", { isVolunteer: false });
    console.log("Client button pressed");
  };
  const showClient = (message) => {
    console.log(message);
    if (Platform.OS === "web") {
      alert(message);
    } else {
      Alert.alert("Information", message, [{ text: "OK" }], {
        cancelable: false,
      });
    }
  };

  const description = () => {
    if (isMouseOverClient) {
      return (
        <View style={styles.descriptionContainer}>
          <Text style={styles.descriptionText}>
            Description for MOW CTX Client
          </Text>
        </View>
      );
    } else if (isMouseOverVolunteer) {
      return (
        <View style={styles.descriptionContainer}>
          <Text style={styles.descriptionText}>Description for Volunteer</Text>
        </View>
      );
    }
    return null;
  };

  const renderLoginHeader = <LoginHeader />;

  return (
    <SafeAreaView style={styles.container}>
      {renderLoginHeader}

      {/*<Image
          source={require('../../../assets/splash1.png')}
          style={styles.image}
  />*/}
      <View style={styles.screenContainer}>
        <>
          {(user == null || user.user_group == "client") && (
            <TouchableOpacity
              style={[styles.buttonContainer, styles.appButtonContainer]}
              onPress={ClientButton}
            >
              <Icon
                name="information-circle"
                size={40}
                color="grey"
                style={styles.imagestyle}
                onPress={() => showClient("Description for MOW CTX Client")}
              />

              <Icon name="people-sharp" size={80} color="white" />

              <Text style={styles.appButtonText}>I am MOWCTX Neighbor</Text>
            </TouchableOpacity>
          )}
          {(user == null || user.user_group != "client") && (
            <TouchableOpacity
              style={[styles.buttonContainer, styles.appButtonContainer]}
              onPress={VolunteerButton}
            >
              <Icon
                name="information-circle"
                size={40}
                color="grey"
                style={styles.imagestyle}
                onPress={() => showClient("Description for MOW CTX Client")}
              />

              <Icon name="people-sharp" size={80} color="white" />

              <Text style={styles.appButtonText}>
                I am a MOWCTX Volunteer or Staff
              </Text>
            </TouchableOpacity>
          )}
        </>
        {/* <TouchableWithoutFeedback
          onPress={ClientButton}
          onPressIn={handleMouseOverClient}
          onPressOut={handleMouseOutClient}
        >
          <View style={[styles.buttonContainer, styles.appButtonContainer]}>
            
            <Text style={styles.appButtonText}>I am a MOW CTX Client</Text>
          </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback
          onPress={VolunteerButton}
          onPressIn={handleMouseOverVolunteer}
          onPressOut={handleMouseOutVolunteer}
        >
          <View style={[styles.buttonContainer, styles.appButtonContainer]}>
            
            <Text style={styles.appButtonText}>I am a Volunteer</Text>
          </View>
        </TouchableWithoutFeedback>
        {description()} */}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  screenContainer: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  appButton: {
    padding: 12,
    alignSelf: "center",
  },
  appButtonContainer: {
    elevation: 8,
    //alignItems: 'center',
    //justifyContent: "center",
    alignContent: "space-between",
    height: 200,
    width: 350,
    marginBottom: 20,
    alignSelf: "center",
    borderRadius: 12,
  },
  imagestyle: {
    elevation: 8,

    height: 50,
    width: 50,
    marginTop: 0,
    top: -10,
    left: 125,
    borderRadius: 12,
  },

  appButtonText: {
    fontSize: 20,
    color: "#fff",
    alignSelf: "center",
    fontWeight: "bold",
    textAlign: "center",
    //textTransform: "uppercase"
  },
  buttonContainer: {
    backgroundColor: "#00A4EF",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: 5,
  },
  image: {
    flex: 1,
    resizeMode: "cover",
    width: 280,
    height: 70,
    marginBottom: 10,
    alignSelf: "center",
  },
  /*icon: {
    fontSize: 40,
    color: "white",
    padding: 10,
  },*/
  descriptionContainer: {
    position: "absolute",
    top: 10,
    alignSelf: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: 10,
    borderRadius: 8,
  },
  descriptionText: {
    fontSize: 16,
    color: "#333",
    textAlign: "center",
  },
});

export default VolunteerChoice;
