import { Image, View, StyleSheet, Text } from "react-native";
import LogoutButton from "../../navigation/screens/login/LogoutButton";

// Header displays the header seen on the MealList screen
const Header = ({ headerText }) => {
  return (
    <View style={stylesH.headerContainer}>
      <View style={stylesH.headerContent}>
        <Text style={stylesH.headerText}>{headerText}</Text>
        <Image
          source={require("../../assets/images/Volunteer/shopping_cart.png")}
          style={stylesH.headerImage}
        />
      </View>
    </View>
  );
};

const stylesH = StyleSheet.create({
  headerContainer: {
    marginBottom: 15,
    // height: "30%",
    maxWidth: "100%",
    backgroundColor: "#E6E7E8",
  },
  headerContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "2.5%",
  },
  headerImage: {
    height: 161,
    width: 150,
    padding: 10,
    marginRight: "2%",
    marginTop: 10,
    marginLeft: "auto",
  },
  headerText: {
    fontSize: 36,
    fontWeight: "500",
    // fontFamily: "Inter-Medium",
    marginLeft: "10%",
    //marginTop: "35%",
  },
});

export default Header;
