import secureLocalStorage from 'react-secure-storage';

export async function getItemAsync(key) {
    return secureLocalStorage.getItem(key);
}

export async function setItemAsync(key, value) {
    secureLocalStorage.setItem(key, value);
}

export async function deleteItemAsync(key) {
    secureLocalStorage.removeItem(key);
}