import { useState, useEffect, useContext } from "react";
import {
  FlatList,
  Text,
  View,
  Modal,
  TouchableWithoutFeedback,
  Dimensions,
  Linking,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Button,
  Pressable,
} from "react-native";
import { FontSize, Color, FontFamily, Border } from "../../styles/GlobalStyles";
import RouteHeader from "./RouteHeader";
import ButtonsContainer from "./ButtonsContainer";
import ModalContent from "./ModalContent";
import ClientButton from "./ClientButton";
import DataContext, { useData } from "../DataContext";
import LogoutButton from "../../navigation/screens/login/LogoutButton";
import colors from "../../styles/colors";
import { AntDesign } from "@expo/vector-icons";
import { useAuth } from "../AuthContext";
import {
  getItemAsync,
  setItemAsync,
  deleteItemAsync,
} from "../../utils/storage";

// Getting device dimensions
const { width, height } = Dimensions.get("window");

// Main component for the RouteSheet
const RouteSheet = ({ navigation }) => {
  // State variables to manage different data and modal visibility
  const [hotMeals, setHotMeals] = useState([]);
  const [coldBags, setColdBags] = useState([]);
  const [specialMeals, setSpecialMeals] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [deliveryModalVisible, setDeliveryModalVisible] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [buttonData, setButtonData] = useState([]);
  // const { clientsData, setClientsData } = useContext(DataContext);
  const { dataState } = useData();
  const { state } = useAuth();
  const [showDialog, setShowDialog] = useState(false);

  // useEffect hook to fetch and process data when clientsData changes
  useEffect(() => {
    const clientsData = dataState.clientsData;
    // console.log("RouteSheet useEffect runs clientsData: ", clientsData);
    const fetchData = async () => {
      try {
        // Processing client data to get counts for hot meals, cold bags, and special meals
        const coldBagsMap = new Map();
        clientsData.forEach((client) => {
          const name = client.coldBag;
          if (name) {
            coldBagsMap.set(name, (coldBagsMap.get(name) || 0) + 1);
          }
        });
        const coldBagsArray = Array.from(coldBagsMap, ([name, count]) => ({
          name,
          count,
        }));

        const hotMealsMap = new Map();
        clientsData.forEach((client) => {
          const name = client.hotColor;
          if (name) {
            hotMealsMap.set(name, (hotMealsMap.get(name) || 0) + 1);
          }
        });
        const hotMealsArray = Array.from(hotMealsMap, ([name, count]) => ({
          name,
          count,
        }));

        const specialMealsMap = new Map();
        clientsData.forEach((client) => {
          const name = client.purpleBag;
          if (name) {
            specialMealsMap.set(name, (specialMealsMap.get(name) || 0) + 1);
          }
        });
        const specialMealsArray = Array.from(
          specialMealsMap,
          ([name, count]) => ({ name, count })
        );
        // Creating buttonDataArray for FlatList rendering
        const buttonDataArray = clientsData.map((client) => ({
          delivery_id: client.delivery_id,
          delivered: client.delivered,
          address: client.address,
          clientName: client.client,
          delivery: client.delivery,
          delivery_instructions: client.delivery_instructions,
          hotColor: client.hotColor,
          coldBag: client.coldBag,
          isAttempted: client.isAttempted,
        }));
        // Updating state variables with the processed data
        setColdBags(coldBagsArray);
        setHotMeals(hotMealsArray);
        setSpecialMeals(specialMealsArray);
        setButtonData(buttonDataArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dataState]);

  // Function to handle button press and show modal
  const handleButtonPress = (buttonName) => {
    setShowDialog(true);
    setModalVisible(true);
    setSelectedButton(buttonName);
  };
  // Function to handle closing the modal
  const handleCloseButtonPress = () => {
    setModalVisible(false);
    setSelectedButton(null);
  };

  const handleOkButtonClick = () => {
    setShowDialog(false);
    setSelectedButton(null);
  };
  // Function to get content for the modal based on the selected button
  const getOverlayContent = () => {
    let content = "";
    // Switch case based on the selected button to determine modal content
    switch (selectedButton) {
      case "HOT MEALS":
        let counter = 0;
        // Calculate count for HOT MEALS
        for (let item of hotMeals) {
          counter += item.count;
        }
        content = counter + " HOT MEALS\n\n";
        hotMeals.forEach((item) => {
          content += `• ${item.count} ${item.name}\n`;
        });
        setShowDialog(true);
        break;
      case "COLD BAGS":
        counter = 0;
        // Calculate count for COLD MEALS
        for (let item of coldBags) {
          counter += item.count;
        }
        content = counter + " COLD BAGS\n\n";
        coldBags.forEach((item) => {
          content += `• ${item.count} ${item.name}\n`;
        });
        setShowDialog(true);
        break;
      case "SPECIAL":
        counter = 0;
        // Calculate count for SPECIAL MEALS
        for (let item of specialMeals) {
          counter += item.count;
        }
        content = counter + " SPECIAL MEALS\n\n";
        specialMeals.forEach((item) => {
          content += `• ${item.count} ${item.name}\n`;
        });
        setShowDialog(true);
        break;
      default:
        break;
    }
    return content;
  };

  return (
    <View style={styles.container}>
      {/* Header component */}
      <RouteHeader headerText={"Today's\nRoute"} />

      {/* Clients today */}
      <Text style={[styles.clientsToday, styles.todays1FlexBox]}>
        <Text style={[styles.text, styles.textTypo]}>
          {dataState.clientsData.length}
        </Text>
        <Text style={styles.clientsTodayFontStyle}> Clients Today</Text>
      </Text>

      {/* ButtonsContainer component */}
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonsContainer
          handleButtonPress={handleButtonPress}
          styles={styles}
        />
      </View>

      {/* Modal component */}
      {/* <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={handleCloseButtonPress}
      >
        <TouchableWithoutFeedback onPress={handleCloseButtonPress}>
        
          <ModalContent
            handleCloseButtonPress={handleCloseButtonPress}
            getOverlayContent={getOverlayContent}
            styles={styles}
          />
        </TouchableWithoutFeedback>
      </Modal> */}

      <Modal visible={showDialog} transparent>
        <View style={styles.modalContainer}>
          <View style={styles.mealmodalContent}>
            <ModalContent
              handleCloseButtonPress={handleCloseButtonPress}
              getOverlayContent={getOverlayContent}
              styles={styles}
            />
            <TouchableOpacity
              onPress={() => handleOkButtonClick()}
              style={styles.okButton}
            >
              <Text style={styles.okButtonText}>Ok</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      {/* Delivery Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={deliveryModalVisible}
        onRequestClose={() => {
          setDeliveryModalVisible(!deliveryModalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalTitle}>
              <Text style={styles.modalTitleText}>Delivery Detail</Text>
              <TouchableOpacity
                onPress={() => {
                  setDeliveryModalVisible(!deliveryModalVisible);
                }}
              >
                <AntDesign name="close" size={25} />
              </TouchableOpacity>
            </View>
            <Text style={styles.modalText}>
              Name: {selectedClient?.clientName}
            </Text>
            <Text style={styles.modalText}>
              Address: {selectedClient?.address}
            </Text>
            <Text style={styles.modalText}>
              Hot Meal: {selectedClient?.hotColor}
            </Text>
            <Text style={styles.modalText}>
              Cold Bag: {selectedClient?.coldBag}
            </Text>
            <Text style={styles.modalText}>
              Delivery: {selectedClient?.delivery}
            </Text>
            <Text style={styles.modalText}>
              Phone:{" "}
              {selectedClient?.phone
                ? selectedClient.phone
                : "No phone number on file"}
            </Text>
            <Text style={styles.modalText}>
              Delivery Instructions: {selectedClient?.delivery_instructions}
            </Text>
            <View style={{ flexDirection: "row", alignSelf: "center" }}>
              <Pressable
                style={styles.deliveryButton}
                onPress={() => {
                  // TODO: record delivery start time and send to server with API
                  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${selectedClient?.address}`;
                  Linking.openURL(mapUrl);
                  navigation.navigate("WellnessCheck", {
                    selectedClient: selectedClient,
                  });
                  setDeliveryModalVisible(false);
                  const deliveryStartTime = new Date();
                  const volunteer_delivery = {
                    delivery_id: selectedClient?.delivery_id,
                    volunteer_first_name: state.user.first_name,
                    volunteer_last_name: state.user.last_name,
                    client_name: selectedClient?.clientName,
                    start_time: deliveryStartTime,
                    end_time: null,
                  };
                  setItemAsync(
                    selectedClient.delivery_id,
                    JSON.stringify(volunteer_delivery)
                  );
                }}
              >
                <View>
                  {" "}
                  <Text style={styles.deliveryText}>Start Navigation</Text>
                </View>
              </Pressable>

              <Pressable
                style={styles.skipButton}
                onPress={() => {
                  // TODO: record delivery start time and send to server with API
                  navigation.navigate("WellnessCheck", {
                    selectedClient: selectedClient,
                  });
                  setDeliveryModalVisible(false);
                  const deliveryStartTime = new Date();
                  const volunteer_delivery = {
                    delivery_id: selectedClient?.delivery_id,
                    volunteer_first_name: state.user.first_name,
                    volunteer_last_name: state.user.last_name,
                    client_name: selectedClient?.clientName,
                    start_time: deliveryStartTime,
                    end_time: null,
                  };
                  setItemAsync(
                    selectedClient.delivery_id,
                    JSON.stringify(volunteer_delivery)
                  );
                }}
              >
                <View>
                  {" "}
                  <Text style={styles.deliveryText}>Skip Navigation</Text>
                </View>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      <View>
        {/* Route Overview */}
        <Text style={[styles.routeOverview, styles.todays1FlexBox]}>
          <Text style={styles.clientsTodayFontStyle}>Route Overview</Text>
        </Text>
      </View>

      {/* FLATLIST */}
      <View
        style={{
          flex: 1,
          height: "50vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 15,
          marginBottom: 10,
          flexGrow: 1,
        }}
      >
        {/* buttonData empty show No routes today */}
        {buttonData.length === 0 && (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              top: "20%",
            }}
          >
            <Text style={[styles.noRoutesToday, styles.clientsTodayFontStyle]}>
              No routes today
            </Text>
          </View>
        )}

        <FlatList
          styles={styles.flatListStyle}
          data={buttonData}
          renderItem={({ item }) => (
            <ClientButton
              address={item.address}
              clientName={item.clientName}
              delivery={item.delivery}
              delivered={item.delivered}
              handleButtonPressMap={() => {
                setSelectedClient(item);
                setDeliveryModalVisible(true);
              }}
              hotColor={item.hotColor}
              coldBag={item.coldBag}
              key={`${item.clientName}-${item.address}`} // Using clientName as the key (assuming it's unique)
              styles={styles}
              isAttempted={item.isAttempted}
            />
          )}
          keyExtractor={(item) => `${item.clientName}-${item.address}`} // Using clientName as the key extractor
        />
      </View>
      <LogoutButton styles={styles} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // height: "100vh",
    backgroundColor: "#FFF",
    justifyContent: "flex-start",
    flex: 1,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    // alignItems: "center",
    minHeight: "50vh",
    marginTop: 15,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 15,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },

  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    marginTop: "5%",
    width: "82%",
  },

  todays1FlexBox: {
    textAlign: "left",
    //position: "absolute",
  },
  clientsTodayFontStyle: {
    // fontFamily: FontFamily.interRegular,
    color: Color.colorBlack,
  },
  clientsToday: {
    top: "2%",
    left: "10%",
    fontSize: 25,
  },
  routeOverview: {
    top: "2%",
    left: "10%",
    fontSize: 25,
  },

  noRoutesToday: {
    top: "2%",
    fontSize: 25,
  },
  text: {
    color: Color.colorDarkturquoise_100,
  },
  textTypo: {
    // fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },

  deliveryButton: {
    //height: height * 0.05,
    //width: width * 0.3,
    backgroundColor: colors.primaryColorVariant,
    borderRadius: 10,
    margin: 5,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  deliveryText: {
    color: "white",
    fontSize: 18,
    padding: 10,
    // fontFamily: FontFamily.semiBold,
  },
  skipButton: {
    //height: height * 0.05,
    //width: width * 0.3,
    backgroundColor: colors.secondaryColorVariant,
    borderRadius: 10,
    margin: 5,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  buttonHotMeals: {
    flex: 1,
    height: height * 0.035,
    width: width * 0.3,
    borderRadius: 10,
    borderColor: "black",
    margin: 5,
    backgroundColor: "#ef4925",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonColdBags: {
    flex: 1,
    height: height * 0.035,
    width: width * 0.3,
    // height: '3.5%',
    // width: '30%',
    backgroundColor: "#00b8d1",
    borderRadius: 10,
    borderColor: "black",
    margin: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonSpecial: {
    flex: 1,
    height: height * 0.035,
    // height: '3.5%',
    // width: '21.3%',
    backgroundColor: "#c4d82e",
    borderRadius: 10,
    borderColor: "black",
    margin: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  logoutButtonContainer: {
    //  position: "absolute",
    bottom: "0",
    // height: "6%",
    borderRadius: 40,
    width: "80%",
    backgroundColor: colors.primaryColor,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10%",
    marginBottom: 15,
    marginTop: "2%",
  },
  buttonText: {
    color: "black",
    // fontFamily: FontFamily.semiBold,
    fontSize: 12,
    textAlign: "center",
  },
  logoutButtonText: {
    fontSize: 24,
    fontWeight: "700",
    // fontFamily: "Inter-Bold",
    color: "#fff",
    padding: 5,
  },
  overlay: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  box: {
    width: "100%",
    //height: "56%",
    // backgroundColor: "#e6e7e8",
    borderRadius: 30,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  modalText: {
    top: "5%",
    // POTENTIAL AB TESTING
    // right: '20%',
    fontSize: 18,
    // fontFamily: FontFamily.interRegular,
    marginBottom: 10,
  },
  modalTitleText: {
    fontSize: 25,
    // fontFamily: FontFamily.interRegular,
    marginBottom: 10,
  },
  modalTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    top: "4%",
    width: "100%",
    // POTENTIAL AB TESTING
    // right: '20%',
    // fontFamily: FontFamily.interRegular,
    marginBottom: 10,
    borderBottomColor: "gray",
    borderBottomWidth: 2,
  },
  closeButton: {
    position: "absolute",
    top: "-6%",
    right: "-6%",
    borderRadius: 15,
    width: "30%",
    height: "30%",
    alignItems: "center",
    justifyContent: "center",
  },
  closeButtonText: {
    color: "grey",
    fontWeight: "bold",
    fontSize: 18,
  },
  routeOverviewContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },

  flatListStyle: {
    top: "7%",
    alignItems: "center",
    width: "100%",
    paddingVertical: "2%",
  },

  textContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  addressText: {
    top: "8%",
    marginBottom: "1%",
  },
  clientNameText: {
    top: "-4%",
    left: "10%",
    fontSize: 20,
  },

  rightArrowImage: {
    width: 15,
    height: 15,
    right: "5%",
    alignContent: "flex-end",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background color
    width: "100%",
    height: "100%",
  },
  mealmodalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    borderWidth: 1, // Add border width
    borderColor: "#C0C0C0", // Add border color
    margin: "5%",
  },
  okButton: {
    backgroundColor: colors.primaryColorVariant, // Customize the button background color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 15,
  },
  okButtonText: {
    color: "white", // Customize the button text color
    fontSize: 16,
  },
  listContainer: {
    flex: 1,
    flexDirection: "row",
  },
});

export default RouteSheet;
