import React, { useEffect, useState } from "react";
import { StatusBar, Text, View } from "react-native";
//import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import ThankYou from "../../components/Volunteer/ThankYou.js";
import MealList from "../../components/Volunteer/MealList.js";
import WellnessCheck from "../../components/Volunteer/WellnessCheck.js";
import RouteSheet from "../../components/Volunteer/RouteSheet.js";
// import DataContext from "../../components/DataContext.js";
import { BASE_URL, PROD_URL } from "../../config.js";
import LogoutButton from "./login/LogoutButton.js";
import { useAuth } from "../../components/AuthContext.js";
import uuid from "react-native-uuid";
import { useData } from "../../components/DataContext.js";

const Stack = createNativeStackNavigator();

function HomeStack() {
  // const [clientsData, setClientsData] = useState(null);
  const [error, setError] = useState(null);
  const { state } = useAuth();
  const { dataState, setClientsData } = useData();

  async function getData() {
    try {
      // Update the URL to point to your Django server's endpoint
      // Make sure the IP address and port match your Django server's configuration
      // If you're running the Django server locally and testing on an emulator,
      // you might need to use a different IP for the emulator to reach the local server
      // KEY HERE IS TO USE YOUR IP ADDRESS INSTEAD OF LOCALHOST FOR TESTING ON EMULATOR
      let response = await fetch(
        //`http://localhost:8000/get_client_names/Abhijit%20Zhu` -> doeesn't work
        //`http://127.0.0.1:8000/get_client_names/Abhijit%20Zhu` -> doeesn't work
        //`http://192.168.1.92:8000/get_client_names/Abhijit%20Zhu` -> works
        //`${BASE_URL}/mowdelivery/get_client_names/Abhijit%20Zhu/`
        // `${BASE_URL}/mowdelivery/get_client_names/kellie%20barton/`
        // `${BASE_URL}/mowdelivery/get_client_names/Nueva/`
        `${BASE_URL}/mowdelivery/get_client_names/${state.user.first_name}%20${state.user.last_name}/`
        //`${BASE_URL}/mowdelivery/get_client_names_test/Aaron%20Gysan/2024-06-24/`
        // 'http://localhost:3000/clients'  // Use this URL for testing with the local mock server
      );
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      let responseJson = await response.json();
      console.log(responseJson);
      return responseJson;
    } catch (error) {
      console.error(error);
      setError(error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getData();
        const modifiedData = data?.map((client) => ({
          ...client,
          delivery: "Not delivered",
          delivered: false,
          delivery_id: uuid.v4(),
        })); // Add a new delivered property to each client and set it to false AND generated delivery id
        // console.log("modifiedData: ", modifiedData);
        setClientsData(modifiedData);
        // console.log("data: ", data);
        // setClientsData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text>Error: {error.message}</Text>
      </View>
    );
  }

  if (dataState.clientsData != null)
    return (
      <Stack.Navigator>
        <Stack.Screen
          name="MealList"
          component={MealList}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="RouteSheet"
          component={RouteSheet}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="WellnessCheck"
          component={WellnessCheck}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ThankYou"
          component={ThankYou}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );
}

export default HomeStack;
