import {
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Text,
  Image,
  Alert,
  Platform,
  Modal,
} from "react-native";
import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Ionicons from "react-native-vector-icons/Ionicons";
import { CheckBox } from "react-native-elements";
import { BASE_URL } from "../../../config.js";
import { useAuth } from "../../../components/AuthContext.js";
import LoginHeader from "../../../shared/LoginHeader.js";
import colors from "../../../styles/colors.js";

TouchableOpacity.defaultProps = { activeOpacity: 0.8 };

const LoginView = () => {
  const nav = useNavigation();

  const route = useRoute();

  const isVolunteer = route.params?.isVolunteer || false;
  const isWeb = () => Platform.OS === "web";
  useEffect(() => {
    //alert('when ar you running')
    loadStoredCredentials("rememberMe");
  }, [rememberMe]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const { signIn } = useAuth();

  const handleLogin = async () => {
    // Reset error messages
    setEmailError("");
    setPasswordError("");
    try {
      if (email.length < 1) {
        //showAlert("Please enter Email ID");
        setEmailError("Please enter email address");
        return;
      } else if (!email.includes("@")) {
        setEmailError("Please enter a valid email address");
        return;
      } else if (password.length < 1) {
        //showAlert("Please enter Password");
        setPasswordError("Please enter password");
        return;
      }

      const backendApiUrl = await axios.post(`${BASE_URL}/auth/login`, {
        email,
        password,
      });
      console.log(backendApiUrl.data);

      // remember me storing
      if (rememberMe) {
        await AsyncStorage.setItem("email", email);
        await AsyncStorage.setItem("rememberMe", "true");
        console.log("storing credentials for remeber me" + email);
      } else {
        await AsyncStorage.removeItem("email");
        await AsyncStorage.removeItem("rememberMe");
      }

      if (backendApiUrl.status === 200) {
        await AsyncStorage.setItem("access", backendApiUrl.data.data.access);
        await AsyncStorage.setItem("refresh", backendApiUrl.data.data.refresh);
        await AsyncStorage.setItem(
          "full_name",
          backendApiUrl.data.data.full_name
        );
        await AsyncStorage.setItem("user_id", backendApiUrl.data.data.user_id);

        const loggedInUser = {
          full_name: backendApiUrl.data.data.full_name,
          first_name: backendApiUrl.data.data.first_name,
          last_name: backendApiUrl.data.data.last_name,
          user_id: backendApiUrl.data.data.user_id,
          user_group: backendApiUrl.data.data.user_group,
        };
        // signIn("dummy-auth-token", loggedInUser);
        signIn(null, loggedInUser);
      } else {
        console.log("Login failed");
        showAlert("Invalid email or password");
      }
    } catch (error) {
      //showAlert(error.response.data.message);
      //showAlert('Unable to contact server. Please contact System Admin.');
      console.log(error.response.data.message);
      setDialogText(error.response.data.message);
      setShowDialog(true);
      console.error("Error", error);
    }
  };

  const handleOkButtonClick = () => {
    setShowDialog(false);
  };

  const loadStoredCredentials = async (fldname) => {
    try {
      const storedremeber = await AsyncStorage.getItem("rememberMe");

      if (storedremeber !== null && storedremeber === "true") {
        setRememberMe(true);
        console.log("setting remember in load " + storedremeber);
        const storedEmail = await AsyncStorage.getItem("email");
        console.log("setting email from load " + storedEmail);
        if (storedEmail !== null) {
          setEmail(storedEmail);
        }
      }
    } catch (error) {
      console.error("Error stored credentials:", error);
    }
  };

  const showAlert = (title, message) => {
    console.log(Platform.OS);
    if (Platform.OS === "web") {
      alert(title, message);
    } else {
      Alert.alert(title, message, [{ text: "OK" }], { cancelable: false });
    }
  };
  /* <Image
          source={require("../../../assets/splash1.png")}
          style={styles.image}
        /> */

  //Render custom header only on web UI
  const renderCustomHeader = isWeb() ? <LoginHeader /> : null;

  return (
    <View style={styles.screenContainer}>
      <Image
        source={require("../../../assets/splash1.png")}
        style={styles.image}
      />
      <View style={[isWeb() && inputstyles.card]}>
        <View
          style={{
            flexDirection: "column",
            borderBottomColor: "#ccc",
            paddingBottom: 8,
            marginBottom: 25,
          }}
        >
          <Text style={inputstyles.text}>Email address</Text>
          <TextInput
            placeholder="Enter your email"
            value={email}
            onChangeText={(text) => setEmail(text)}
            style={inputstyles.input}
            keyboardType="email-address"
          />
          {emailError ? (
            <Text style={{ color: "red" }}>{emailError}</Text>
          ) : null}
        </View>

        <View
          style={{
            flexDirection: "column",
            borderBottomColor: "#ccc",
            paddingBottom: 8,
            marginBottom: 25,
          }}
        >
          <Text style={inputstyles.text}>Password</Text>
          <TextInput
            placeholder="Password"
            value={password}
            onChangeText={(text) => setPassword(text)}
            secureTextEntry={true}
            style={inputstyles.input}
          />
          {passwordError ? (
            <Text style={{ color: "red" }}>{passwordError}</Text>
          ) : null}
        </View>
        <CheckBox
          title="Remember me"
          checked={rememberMe}
          onPress={() => setRememberMe(!rememberMe)}
        />

        <AppButton
          title="Login"
          backgroundColor={colors.primaryColorVariant}
          onPress={handleLogin}
        />
      </View>
      <Modal visible={showDialog} transparent>
        <View style={inputstyles.modalContainer}>
          <View style={inputstyles.modalContent}>
            <Text>{dialogText}</Text>
            <TouchableOpacity
              onPress={() => handleOkButtonClick()}
              style={inputstyles.okButton}
            >
              <Text style={inputstyles.okButtonText}>Ok</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};
const AppButton = ({ onPress, title, backgroundColor }) => (
  <TouchableOpacity
    onPress={onPress}
    style={[styles.appButtonContainer, backgroundColor && { backgroundColor }]}
  >
    <Text style={styles.appButtonText}>{title}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  appButton: {
    padding: 12,
  },
  appButtonContainer: {
    elevation: 8,
    alignItems: "center",
    justifyContent: "center",
    height: 50,
    marginTop: 10,
    alignItems: "center",
    borderRadius: 25,
  },
  appButtonText: {
    fontSize: 18,
    color: "#fff",
    alignSelf: "center",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  image: {
    width: 120,
    height: 120,
    marginBottom: 5,
    alignSelf: "center",
  },
  input: {
    width: 300,
    height: 40,
    borderWidth: 1,
    borderColor: "gray",
    marginBottom: 10,
    paddingHorizontal: 10,
  },
});

const inputstyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#228B22",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  title: {
    fontSize: 40,
    color: "#fff",
    marginBottom: 20,
    //fontWeight: "bold",
  },
  text: {
    fontSize: 16,
    // fontWeight: 400,
  },
  input: {
    backgroundColor: "#fff",
    padding: 10,
    width: "100%",
    marginTop: 15,
    height: 48,
    borderColor: "#000000",
    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width: "70%",
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    paddingTop: 20,
    marginHorizontal: 150,
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background color
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    borderWidth: 1, // Add border width
    borderColor: "#C0C0C0", // Add border color
  },
  okButton: {
    backgroundColor: colors.primaryColorVariant, // Customize the button background color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 15,
  },
  okButtonText: {
    color: "white", // Customize the button text color
    fontSize: 16,
  },
});

export default LoginView;
