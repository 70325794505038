import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Button,
  SafeAreaView,
  Pressable,
  Modal,
  Image,
  Platform,
  ScrollView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { LinearGradient } from "expo-linear-gradient";
import ProgressBar from "../../shared/ProgressBar";
import DialogBox from "../../shared/DialogBox";
import colors from "../../styles/colors";
import { BASE_URL } from "../../config.js";

const NeighborCheckIn = ({
  isVisible,
  onClose,
  submitAction,
  neighborName,
}) => {
  const [quizData, setQuizData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const navigation = useNavigation();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [counter, setCounter] = useState(0);
  const totalQuestions = 5;
  const [dialogText, setDialogText] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [user_id, setUserid] = useState("");

  const [modalVisible, setModalVisible] = useState(isVisible);

  useEffect(() => {
    setModalVisible(isVisible);
  }, [isVisible]);

  // Replace 'API_URL' with the actual API URL from where you fetch the data
  useEffect(() => {
    if (user_id.length === 0) {
      getUserID();
    }
    navigation.setOptions({
      headerShown: false,
    });

    /*fetch(
      `${BASE_URL}/surveys/get-questionnaires/?user_id=c2b5c9ac-d003-4463-9cd0-8c351c6d13f3&survey_group=FOOD_ACCESS`
    )
      .then((response) => response.json())
      .then((data) => setQuizData(data.data))
      .catch((error) => console.error(error));*/
  }, []);
  const getUserID = async () => {
    const value = await AsyncStorage.getItem("user_id");
    if (value !== null) {
      console.log(
        `${BASE_URL}/mowdelivery/checkin/questions/?user_id=` + value
      );
      fetch(`${BASE_URL}/mowdelivery/checkin/questions/?user_id=` + value)
        .then((response) => response.json())
        .then((data) => {
          const nutritionalRiskQuestions = [data.nutritional_risk].map(
            (question) => ({
              ...question,
              category: "Nutritional Risk",
            })
          );
          const socialIsolationQuestions = [data.social_isolation].map(
            (question) => ({
              ...question,
              category: "Social Isolation",
            })
          );
          const volunteerQuestions = data.volunteer_questions.map(
            (question) => ({
              ...question,
              category: "Volunteer",
            })
          );
          const combinedQuestions = nutritionalRiskQuestions
            .concat(socialIsolationQuestions)
            .concat(volunteerQuestions);
          console.log(combinedQuestions);
          setQuizData(combinedQuestions);
        })
        .catch((error) => console.error(error));
      setUserid(value);
    }
  };
  const handleNextQuestion = () => {
    const currentQuestion = quizData[currentQuestionIndex];
    const questionId = currentQuestion.id;

    if (!selectedAnswers[questionId]) {
      // If no option is selected, show the dialog
      setDialogText("Please select an option before proceeding.");
      setShowDialog(true);
    } else {
      // If an option is selected, move to the next question
      setShowDialog(false);
      setSelectedOption(null);
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setCounter((prevCounter) => prevCounter + 1);

      if (currentQuestionIndex === quizData.length - 1) {
        // If it's the last question, submit the quiz
        setShowSubmitButton(true);
      }
    }
  };

  const handlePreviousQuestion = () => {
    setCounter((prevCounter) => prevCounter - 1);
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setShowSubmitButton(false);
  };

  const handleOptionSelect = (questionId, answer) => {
    setSelectedOption(answer);
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));

    if (currentQuestionIndex === quizData.length - 1) {
      if (answer === "No") {
        console.log("selectedAnswers No : " + answer);
        setShowSubmitButton(true);
      } else if (answer === "Yes") {
        console.log("selectedAnswers Yes : " + answer);
        setShowSubmitButton(false);
      }
    }
  };

  const handleSubmit = async () => {
    const currentQuestion = quizData[currentQuestionIndex];
    const questionId = currentQuestion.id;

    if (!selectedAnswers[questionId]) {
      // If no option is selected, show the dialog
      setDialogText("Please select an option before submitting.");
      setShowDialog(true);
    } else {
      const responses = quizData.map((question) => ({
        question: question.id,
        response: question.answers[0].id, // Assuming the response ID is the ID of the first answer object
        user_response: {
          selected_choice: selectedAnswers[question.id], // Assuming 'selectedAnswers' holds the user's choices keyed by question ID
        },
      }));
      console.log("Neighbor Name: " + neighborName);
      const requestBody = {
        delivery: 99,
        neighbor_name: neighborName,
        responses: responses,
      };
      console.log("Submitted Request: " + JSON.stringify(requestBody));
      try {
        const url =
          `${BASE_URL}/mowdelivery/checkin/submit/?user_id=` + user_id;
        const response = await axios.post(url, requestBody);
        setDialogText("Your response has been successfully submitted.");
        setShowDialog(true);
        submitAction(true);
        console.log("Response data:", response.data);
        setSubmitted(true);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const renderOption = ({ item }) => {
    const questionId = quizData[currentQuestionIndex].id;
    const isSelected = selectedAnswers[questionId] === item;

    return (
      <View>
        <TouchableOpacity onPress={() => handleOptionSelect(questionId, item)}>
          <View
            style={[
              styles.optionContainer,
              isSelected && styles.selectedOption,
            ]}
          >
            <Text style={styles.optionText}>{item}</Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  if (!quizData) {
    return <Text>Loading...</Text>;
  }

  const currentQuestion = quizData[currentQuestionIndex];
  console.log("Rendering question ID:", quizData[currentQuestionIndex].id);
  return (
    <Modal
      visible={modalVisible}
      animationType="slide"
      onRequestClose={onClose}
      transparent={false}
    >
      <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
        <ScrollView showsVerticalScrollIndicator={true}>
          <View style={[styles.container, isWeb() && styles.card]}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: "bold",
                  color: colors.primaryColor,
                }}
              >
                {currentQuestion.category}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <Text
                style={{
                  color: colors.primaryColor,
                  fontSize: 16,
                }}
              >
                Progress
              </Text>
              <Text
                style={{
                  color: colors.primaryColor,
                  fontSize: 16,
                }}
              >
                Question {counter + 1}/{totalQuestions}
              </Text>
            </View>
            <ProgressBar
              currentQuestion={counter}
              totalQuestions={totalQuestions}
            />
            <View style={{ alignItems: "center", justifyContent: "center" }}>
              <Text
                style={{
                  fontSize: 17,
                  fontWeight: "bold",
                  margin: 10,
                }}
              >
                {counter + 1}. {currentQuestion.question}
              </Text>
            </View>
            <View style={{ marginTop: 12, alignItems: "center" }}>
              {currentQuestion.answers[0].choices ? (
                <FlatList
                  style={{ width: "90%" }}
                  data={currentQuestion.answers[0].choices}
                  renderItem={renderOption}
                  keyExtractor={(item, index) => index.toString()}
                />
              ) : (
                <Text>No options available</Text>
              )}
            </View>

            <View style={styles.buttonContainer}>
              <Button
                title="Previous"
                onPress={handlePreviousQuestion}
                disabled={currentQuestionIndex === 0}
                color={colors.primaryColorVariant}
              />

              {showSubmitButton ||
              currentQuestionIndex === quizData.length - 1 ? (
                <Button
                  title="Submit"
                  onPress={handleSubmit}
                  color={colors.primaryColorVariant}
                />
              ) : (
                <Button
                  title="Next"
                  onPress={handleNextQuestion}
                  color={colors.primaryColorVariant}
                />
              )}
            </View>
          </View>
        </ScrollView>

        <DialogBox
          isVisible={showDialog}
          onClose={() => {
            setShowDialog(false);
            if (submitted) {
              onClose();
            }
          }}
          text={dialogText}
          primaryButtonText="Close"
          onPrimaryPress={() => {
            setShowDialog(false);
            if (submitted) {
              onClose();
            }
          }}
        />
      </SafeAreaView>
    </Modal>
  );
};
const isWeb = () => Platform.OS === "web";
const styles = {
  container: {
    padding: 10,
    backgroundColor: "white",
  },
  questionText: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 16,
    color: colors.primaryColor,
  },
  optionContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1.5,
    borderColor: colors.secondaryColor,
    marginVertical: 10,
    borderRadius: 20,
  },
  selectedOption: {
    backgroundColor: "#6B9626",
    borderWidth: 2,
    borderColor: "#c3d5a8",
  },
  optionText: {
    padding: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  card: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    marginHorizontal: 150,
    marginVertical: 40,
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 7,
  },
};

export default NeighborCheckIn;
