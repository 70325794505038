/* fonts */
export const FontFamily = {
    interBold: "Inter-Bold",
    interMedium: "Inter-Medium",
    interRegular: "Inter-Regular",
    semiBold: "Inter-SemiBold",
};
/* font sizes */
export const FontSize = {
size_xl: 20,
size_base: 16,
};
/* Colors */
export const Color = {
colorWhite: "#fff",
colorDarkturquoise_100: "#00b8d1",
colorBlack: "#000",
};
/* border radiuses */
export const Border = {
br_3xs: 10,
};
  