import { View, Text, StyleSheet } from "react-native";
import React from "react";
import colors from "../styles/colors";

const ProgressBar = ({ currentQuestion, totalQuestions }) => {
  const progressPercentage = (currentQuestion / totalQuestions) * 100;

  return (
    <View style={styles.progressBarContainer}>
      <View style={[styles.progressBar, { width: `${progressPercentage}%` }]} />
      <Text style={styles.progressText}>{`${progressPercentage.toFixed(
        2
      )}% Complete`}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  progressBarContainer: {
    backgroundColor: "#EFEFEF",
    borderRadius: 4,
    height: 16,
    marginVertical: 8,
    overflow: "hidden",
  },
  progressBar: {
    height: 16,
    backgroundColor: colors.tertiaryColor,
  },
  progressText: {
    fontSize: 12,
    color: "#007BFF",
    textAlign: "right",
    marginTop: 2,
  },
});

export default ProgressBar;
