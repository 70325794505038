import {
  View,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Text,
  Alert,
  SafeAreaView,
  ScrollView,
} from "react-native";
import React, { useLayoutEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import LoginHeader from "../../../shared/LoginHeader";
import { BASE_URL } from "../../../config.js";
import colors from "../../../styles/colors.js";

TouchableOpacity.defaultProps = { activeOpacity: 0.8 };
const Advocate = () => {
  const nav = useNavigation();
  var erString = "";
  const [client_first_name, setClientFirstName] = useState("");
  const [client_last_name, setClientLastName] = useState("");
  const [client_email, setClientEmail] = useState("");
  const [phone, setClientPhone] = useState("");
  const [referer_first_name, setReferFirstName] = useState("");
  const [referer_last_name, setReferLastName] = useState("");
  const [referer_email, setReferEmail] = useState("");
  const [referer_phone, setReferPhone] = useState("");

  const isWeb = () => Platform.OS === "web";

  const referUser = async (
    client_first_name,
    client_last_name,
    client_email,
    phone,
    referer_first_name,
    referer_last_name,
    referer_email,
    referer_phone
  ) => {
    try {
      const referAPI = await axios.post(`${BASE_URL}/user-referral/`, {
        client_first_name,
        client_last_name,
        client_email,
        phone,
        referer_first_name,
        referer_last_name,
        referer_email,
        referer_phone,
      });
      if (referAPI.status === 200) {
        console.log("Refer Successful");
        nav.navigate("LoginView");
      } else {
        showAlert("Error:", "Unable to Refer at this Time");
        console.log("Refer Failed");
      }
    } catch (error) {
      console.error("Error during referral:", error.message);
    }
  };

  const handleRefer = () => {
    if (client_first_name === 0) {
      erString = erString + " Missing Client First Name, ";
    }
    if (client_last_name === 0) {
      erString = erString + " Missing Client Last Name, ";
    }
    if (!validateEmail(client_email)) {
      erString = erString + " Client Email, ";
    }
    if (!validatePhoneNumber(phone)) {
      erString = erString + " Client Phone Number ";
    }
    if (referer_first_name === 0) {
      erString = erString + " Missing Referer First Name, ";
    }
    if (referer_last_name === 0) {
      erString = erString + " Missing Referer Last Name, ";
    }
    if (!validateEmail(referer_email)) {
      erString = erString + " Referer Email, ";
    }
    if (!validatePhoneNumber(referer_phone)) {
      erString = erString + " Referer Phone Number ";
    }
    if (erString.length > 1) {
      showAlert("Invalid " + erString.substring(0, erString.length - 1));
      return;
    }

    referUser(
      client_first_name,
      client_last_name,
      client_email,
      phone,
      referer_first_name,
      referer_last_name,
      referer_email,
      referer_phone
    );
    console.log("Referral Successful");
    showAlert("Create Account", "Your information has been submitted to Nueva");
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const validatePhoneNumber = (phone) => {
    return !isNaN(phone) && phone.length >= 10;
  };
  const showAlert = (title, message) => {
    console.log(Platform.OS);
    if (Platform.OS === "web") {
      alert(title, message);
    } else {
      Alert.alert(title, message, [{ text: "OK" }], { cancelable: false });
    }
  };

  const renderLoginHeader = isWeb() ? (
    <LoginHeader
      style={{
        width: "100%",
      }}
    />
  ) : null;

  return (
    <SafeAreaView
      style={{
        backgroundColor: "white",
      }}
    >
      {renderLoginHeader}
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <Text style={styles.title}>Refer a friend or loved one</Text>
        <View style={[isWeb() && styles.card]}>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Client first name</Text>
            <TextInput
              placeholder="Enter your first name"
              value={client_first_name}
              onChangeText={(text) => setClientFirstName(text)}
              style={styles.input}
            />
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Client last name</Text>
            <TextInput
              placeholder="Enter your last name"
              value={client_last_name}
              onChangeText={(text) => setClientLastName(text)}
              style={styles.input}
            />
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Client email address</Text>
            <TextInput
              placeholder="Enter your email address[Optional]"
              value={client_email}
              onChangeText={(text) => setClientEmail(text)}
              style={styles.input}
            />
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Client phone number</Text>
            <TextInput
              placeholder="Enter your phone number"
              value={phone}
              onChangeText={(text) => setClientPhone(text)}
              style={styles.input}
            />
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Referer first name</Text>
            <TextInput
              placeholder="Enter referer's first name"
              value={referer_first_name}
              onChangeText={(text) => setReferFirstName(text)}
              style={styles.input}
            />
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Referer last name</Text>
            <TextInput
              placeholder="Enter referer's last name"
              value={referer_last_name}
              onChangeText={(text) => setReferLastName(text)}
              style={styles.input}
            />
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Referer email address</Text>
            <TextInput
              placeholder="Enter referer's email address"
              value={referer_email}
              onChangeText={(text) => setReferEmail(text)}
              style={styles.input}
            />
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Referer phone number</Text>
            <TextInput
              placeholder="Enter referer's phone number"
              value={referer_phone}
              onChangeText={(text) => setReferPhone(text)}
              style={styles.input}
            />
          </View>
          <View style={styles.buttonAlign}>
            <AppButton
              title="REFER"
              backgroundColor={colors.primaryColorVariant}
              onPress={handleRefer}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};
const AppButton = ({ onPress, title, backgroundColor }) => (
  <TouchableOpacity
    style={[styles.appButtonContainer, backgroundColor && { backgroundColor }]}
  >
    <Text style={styles.appButtonText} onPress={onPress}>
      {title}
    </Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 80,
  },
  appButton: {
    padding: 12,
    alignSelf: "center",
  },
  appButtonContainer: {
    elevation: 8,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "space-between",
    height: 50,
    width: 200,
    marginTop: 20,
    borderRadius: 25,
    width: "90%",
  },
  appButtonText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase",
  },
  buttonAlign: {
    flexDirection: "column",
    borderBottomColor: "#ccc",
    paddingBottom: 8,
    marginBottom: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    backgroundColor: "#fff",
    padding: 10,
    width: "100%",
    marginTop: 15,
    height: 48,
    borderColor: "#000000",
    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  viewStyle: {
    flexDirection: "column",
    borderBottomColor: "#ccc",
    paddingBottom: 8,
    marginBottom: 25,
  },

  card: {
    width: "50%",
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    paddingTop: 20,
    marginHorizontal: 150,
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 10,
  },

  text: {
    fontSize: 16,
  },

  title: {
    fontSize: 30,
    fontWeight: "bold",
    color: colors.primaryColor,
    flexDirection: "row",
    alignContent: "center",
    marginBottom: 20,
  },
});
export default Advocate;
