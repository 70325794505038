import React, { useLayoutEffect } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  SafeAreaView,
  ScrollView,
  Platform,
} from "react-native";
import LoginHeader from "../../../shared/LoginHeader";
TouchableOpacity.defaultProps = { activeOpacity: 0.8 };

const LoginButtons = () => {
  const nav = useNavigation();
  const route = useRoute();
  const isWeb = () => Platform.OS === "web";

  const isVolunteer = route.params?.isVolunteer || false;
  console.log({isVolunteer}) 

  const handleRegister = () => {
    if (isVolunteer) {
      nav.navigate("VolunteerRegister");
    } else {
      nav.navigate("KnockOut", { isAdvocate: false });
    }
  };

  const renderLoginHeader = isWeb() ? <LoginHeader /> : null;
  return (
    <SafeAreaView style={styles.screenContainer}>
      {renderLoginHeader}
      <ScrollView showsVerticalScrollIndicator={true}>
        <AppButton
          title="LOGIN"
          backgroundColor="#00A4EF"
          onPress={() => nav.navigate("LoginView", { isVolunteer: false })}
        />
        <Text
          style={{
            textAlign: "center",
            color: "#666",
            marginTop: 20,
            marginBottom: 5,
          }}
        >
          ----Or----
        </Text>
        <AppButton
          title="REGISTER"
          backgroundColor="#CC397B"
          onPress={handleRegister}
        />
        <Text
          style={{
            textAlign: "center",
            color: "#666",
            marginTop: 20,
            marginBottom: 5,
          }}
        >
          ----Or----
        </Text>
        <AppButton
          title="REFER"
          backgroundColor="#008080"
          onPress={() => nav.navigate("KnockOut", { isAdvocate: true })}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

const AppButton = ({ onPress, title, backgroundColor }) => (
  <TouchableOpacity
    onPress={onPress}
    style={[styles.appButtonContainer, backgroundColor && { backgroundColor }]}
  >
    <Text style={styles.appButtonText}>{title}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  screenContainer: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
  },
  appButton: {
    padding: 12,
    alignSelf: "center",
  },
  appButtonContainer: {
    elevation: 8,
    //alignItems: 'center',
    justifyContent: "center",
    alignContent: "space-between",
    height: 50,
    width: 200,
    marginTop: 20,
    alignSelf: "center",
    borderRadius: 12,
  },
  appButtonText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase",
  },
  image: {
    flex: 1,
    resizeMode: "cover",
  },
});

export default LoginButtons;
