import React, { useContext, useReducer } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import VolunteerChoice from "./navigation/screens/login/VolunteerChoice";
import LoginButtons from "./navigation/screens/login/LoginButtons";
import VolunteerButtons from "./navigation/screens/login/VolunteerButtons";
import LoginView from "./navigation/screens/login/LoginView";
import SignUpView from "./navigation/screens/login/SignUpView";
import KnockOut from "./navigation/screens/login/KnockOut";
import VolunteerRegister from "./navigation/screens/login/VolunteerRegister";
import Advocate from "./navigation/screens/login/Advocate"
import HomeScreen from "./navigation/HomeScreen";
import HomeStack from "./navigation/screens/HomeStack";
import FoodAndDrinks from "./navigation/screens/assessment/FoodAndDrinks";
import SocialSupport from "./navigation/screens/assessment/SocialSupport";
import QualityOfLife from "./navigation/screens/assessment/QualityOfLife";
import FoodAccess from "./navigation/screens/assessment/FoodAccess";
import MealProviderOption from "./navigation/screens/meals/MealProviderOption";
import MenuChoiceForm from "./navigation/screens/meals/MenuChoiceForm";
import MealCalendar from "./navigation/screens/meals/MealCalendar";
import WeekSelection from "./navigation/screens/meals/WeekSelection";
import MealSelection from "./navigation/screens/meals/MealSelection";
import FavoritesScreen from "./navigation/FavoritesScreen";
import Profile from "./navigation/Profile";
import CalendarWeekSelection from "./navigation/screens/meals/CalendarWeekSelection";
import { BASE_URL } from "./config";
import { configureInternal } from "@testing-library/react-native/build/config";
import * as Linking from 'expo-linking'

import { NativeWindStyleSheet } from "nativewind";
import { AuthProvider, useAuth } from "./components/AuthContext";
import AppNavigator from "./navigation/AppNavigator";
import { DataProvider } from "./components/DataContext";

export default function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <AppNavigator />
      </DataProvider>
    </AuthProvider>
  );
};
