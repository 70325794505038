import { Image, View, StyleSheet, Text } from "react-native";
import LogoutButton from "../../navigation/screens/login/LogoutButton";

// Header component definition with a dynamic headerText prop
const RouteHeader = ({ headerText }) => {
  return (
    <View style={styles.headerContainer}>
      <View style={styles.headerContent}>
        <Text style={styles.headerText}>{headerText}</Text>
        <Image
          source={require("../../assets/images/Volunteer/shopping_cart1.png")}
          style={styles.headerImage}
        />
      </View>
    </View>
  );
};

// Styles for the Header component
const styles = StyleSheet.create({
  headerContainer: {
    marginBottom: 15,
    // height: "30%",
    maxWidth: "100%",
    backgroundColor: "#E6E7E8",
  },
  headerContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    //marginTop: "16%",
  },
  headerImage: {
    height: 161,
    width: 150,
    marginRight: "2%",
    marginTop: 10,
    marginLeft: "auto",
  },
  headerText: {
    fontSize: 36,
    fontWeight: "500",
    // fontFamily: "Inter-Medium",
    marginLeft: "10%",
    // marginTop: "19%",
  },
});

export default RouteHeader;
