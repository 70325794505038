import { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  ScrollView,
  Platform,
} from "react-native";
import MealCard from "./MealCard";
import Header from "./Header";
import DataContext, { useData } from "../DataContext";
import LogoutButton from "../../navigation/screens/login/LogoutButton";
import { useAuth } from "../AuthContext";
import { data } from "autoprefixer";
import colors from "../../styles/colors";

const MealList = ({ navigation }) => {
  const [hotMeals, setHotMeals] = useState([]);
  const [coldBags, setColdBags] = useState([]);
  const [specialMeals, setSpecialMeals] = useState([]);
  //const clientsData = useContext(DataContext);
  // const { clientsData, setClientsData } = useContext(DataContext); //  || { data: [], setData: () => {} };
  const { dataState } = useData();
  const clientsData = dataState.clientsData;
  const isWeb = () => Platform.OS === "web";

  // console.log("MealList clientsData: ", clientsData);

  useEffect(() => {
    // console.log("MealList useEffect runs");
    // console.log("dataState clientsData: ", dataState.clientsData);
    const fetchData = async () => {
      try {
        // console.log("data: ", dataState.clientsData);

        const coldBagsMap = new Map();
        // Iterate over the data and accumulate counts for cold bags
        clientsData.forEach((client) => {
          const name = client.coldBag;
          if (name) {
            // update count for existing cold bag type
            if (coldBagsMap.has(name)) {
              coldBagsMap.set(name, coldBagsMap.get(name) + 1);
              // otherwise add new type of cold bag with count 1
            } else {
              coldBagsMap.set(name, 1);
            }
          }
        });
        const coldBagsArray = Array.from(coldBagsMap, ([name, count]) => ({
          name,
          count,
        }));

        const hotMealsMap = new Map();
        // Iterate over the data and accumulate counts for hot meals
        clientsData.forEach((client) => {
          const name = client.hotColor;
          if (name) {
            if (hotMealsMap.has(name)) {
              hotMealsMap.set(name, hotMealsMap.get(name) + 1);
            } else {
              hotMealsMap.set(name, 1);
            }
          }
        });
        const hotMealsArray = Array.from(hotMealsMap, ([name, count]) => ({
          name,
          count,
        }));

        const specialMealsMap = new Map();
        // Iterate over the data and accumulate counts for special meals
        clientsData.forEach((client) => {
          const name = client.purpleBag;
          if (name) {
            if (specialMealsMap.has(name)) {
              specialMealsMap.set(name, specialMealsMap.get(name) + 1);
            } else {
              specialMealsMap.set(name, 1);
            }
          }
        });
        const specialMealsArray = Array.from(
          specialMealsMap,
          ([name, count]) => ({ name, count })
        );
        // update data for the 3 types of meals
        setColdBags(coldBagsArray);
        setHotMeals(hotMealsArray);
        setSpecialMeals(specialMealsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dataState]); // TODO REMOVE: return to empty dependencies [] when done testing with json-server

  console.log("Cold: ", coldBags);
  console.log("Hot: ", hotMeals);
  console.log("Speial: ", specialMeals);

  // method to naviagte to Route Sheet on button click
  const handleRoutePress = () => {
    navigation.navigate("RouteSheet");
  };

  return (
    <View style={[styles.container, !isWeb() && styles.mobileStyle]}>
      <Header headerText={"Meal List Today"} />
      {/* <View style={styles.clientContainer}>
        <Text style={styles.clientCount}>{clientsData.length}</Text>
        <Text style={styles.clientText}>Clients Today</Text>
      </View>*/}
      <View style={styles.scrollContainer}>
        <MealCard
          bagList={hotMeals}
          bagTitle={"HOT MEALS"}
          backgroundColor={"#ef492580"}
        />
        <MealCard
          bagList={coldBags}
          bagTitle={"COLD BAGS"}
          backgroundColor={"#00b8d180"}
        />
        <MealCard
          bagList={specialMeals}
          bagTitle={"SPECIAL MEALS"}
          backgroundColor={"#c4d82e80"}
        />
      </View>
      <View>
        <TouchableOpacity
          onPress={handleRoutePress}
          style={styles.buttonContainer}
        >
          <Text style={styles.buttonText}>View Route</Text>
        </TouchableOpacity>
      </View>
      <View>
        <LogoutButton styles={styles} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    marginBottom: "30%",
  },
  container: {
    flex: 1,
    width: "100%",
    //position: "relative",
  },
  buttonContainer: {
    //  position: "absolute",
    //height: "6%",
    bottom: "5%",
    borderRadius: 40,
    width: "80%",
    backgroundColor: "#00B8D1",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10%",
    marginBottom: 25,
  },
  logoutButtonContainer: {
    //  position: "absolute",
    // bottom: "5%",
    // height: "6%",
    borderRadius: 40,
    width: "80%",
    backgroundColor: colors.primaryColor,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10%",
    marginBottom: 15,
  },
  buttonText: {
    fontSize: 24,
    fontWeight: "700",
    // fontFamily: "Inter-Bold",
    color: "#fff",
    padding: 5,
  },
  logoutButtonText: {
    fontSize: 24,
    fontWeight: "700",
    // fontFamily: "Inter-Bold",
    color: "#fff",
    padding: 5,
  },
  clientContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
    marginLeft: "10%",
  },
  clientCount: {
    fontSize: 24,
    fontWeight: "500",
    // fontFamily: "Inter-Medium",
    color: "#00b8d1",
  },
  clientText: {
    fontSize: 24,
    // fontFamily: "Inter-Regular",
    marginLeft: 8,
  },
  mobileStyle: {
    marginTop: "16%",
  },
});

export default MealList;
