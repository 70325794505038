import React from "react";
import colors from "../styles/colors";
import PropTypes from "prop-types";
import { Modal, View, Text, TouchableOpacity, StyleSheet } from "react-native";

const DialogBox = ({
  isVisible,
  onClose,
  text,
  primaryButtonText,
  onPrimaryPress,
  secondaryButtonText,
  onSecondaryPress,
}) => (
  <Modal
    visible={isVisible}
    transparent={true}
    animationType="fade"
    onRequestClose={onClose}
  >
    <View style={styles.centeredView}>
      <View style={styles.modalView}>
        <Text style={styles.modalText}>{text}</Text>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={onPrimaryPress}
          >
            <Text style={styles.buttonText}>{primaryButtonText}</Text>
          </TouchableOpacity>
          {onSecondaryPress && (
            <TouchableOpacity
              style={styles.button}
              onPress={onSecondaryPress}
            >
              <Text style={styles.buttonText}>{secondaryButtonText}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  </Modal>
);

DialogBox.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  primaryButtonText: PropTypes.string,
  onPrimaryPress: PropTypes.func.isRequired,
  onSecondaryPress: PropTypes.func,
  secondaryButtonText: PropTypes.string,
};

DialogBox.defaultProps = {
  primaryButtonText: "OK",
  onSecondaryPress: null,
  secondaryButtonText: "Cancel",
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
  },
  modalText: {
    fontSize: 16,
    marginBottom: 15,
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    backgroundColor: colors.primaryColorVariant,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 15,
    marginHorizontal: 5,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
});

export default DialogBox;
