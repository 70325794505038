import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  Platform,
} from "react-native";
import React, { useLayoutEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { SafeAreaView } from "react-native";
import MealProviderOption from "../navigation/screens/meals/MealProviderOption";

const Meals = () => {
  const navigation = useNavigation();

  const styles = StyleSheet.create({
    meal: {
      margin: 20,
      backgroundColor: "white",
      padding: 20,
      borderRadius: 15,
      borderWidth: 1.5,
      borderColor: "#C0C0C0",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      elevation: 5, // This adds the shadow to the component (Android)
      shadowColor: "black", // Shadow color (iOS)
      shadowOffset: { width: 0, height: 2 }, // Shadow offset (iOS)
      shadowOpacity: 0.3, // Shadow opacity (iOS)
      shadowRadius: 4, // Shadow blur radius (iOS)
    },
    image: {
      width: 260,
      height: 210,
      alignContent: "center",
      justifyContent: "center",
    },

    text: {
      textAlign: "center",
      marginTop: 15,
      fontWeight: "bold",
      fontSize: 18,
    },

    webContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      paddingHorizontal: 20,
      marginVertical: 10,
      alignItems: "center", // Center the content horizontally
      justifyContent: "center", // Center the content vertically
      marginHorizontal: 45,
    },
    webMeal: {
      width: "90%",
      marginVertical: 10,
      backgroundColor: "white",
      borderRadius: 15,
      borderWidth: 2,
      padding: 15,
      borderColor: "#C0C0C0",
      elevation: 5,
      shadowColor: "#C0C0C0",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.3,
      shadowRadius: 4,
      alignItems: "center", // Center the content horizontally
      justifyContent: "center", // Center the content vertically
      marginHorizontal: 10,
    },
    webImage: {
      width: "30%",
      height: 235,
      borderRadius: 15,
      padding: 10,
    },
  });
  const isWeb = Platform.OS === "web"; // Check if the platform is web
  return (
    <View style={[isWeb && styles.webContainer]}>
      <Pressable
        style={[!isWeb && styles.meal, isWeb && styles.webMeal]}
        onPress={() => navigation.navigate('MealProviderOption')}
      >
        <Image
          source={require("../assets/images/meal/newccaimagebig.jpeg")}
          style={styles.image}
        />
        <Text style={styles.text}>Meals</Text>
      </Pressable>
    </View>
  );
};

export default Meals;
