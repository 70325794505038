import React from "react";
import { View, Image, SafeAreaView, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import colors from "../styles/colors";

const LoginHeader = () => {
  const nav = useNavigation();

  const handleImagePress = () => {
    nav.navigate("VolunteerChoice");
  };

  return (
    <SafeAreaView>
      <View style={{ backgroundColor: colors.primaryColorVariant }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            padding: 12,
            marginTop: 30,
            backgroundColor: colors.primaryColor,
          }}
        >
          <TouchableOpacity onPress={handleImagePress}>
            <Image
              source={require("../assets/splash1.png")}
              style={{ height: 75, width: 78, marginHorizontal: 5 }}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ height: 30 }}></View>
    </SafeAreaView>
  );
};

export default LoginHeader;
