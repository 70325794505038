import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Button,
  SafeAreaView,
  Pressable,
  Modal,
  Image,
  Platform,
  ScrollView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from "axios";
import CustomHeader from "../../../shared/CustomHeader";
import { LinearGradient } from "expo-linear-gradient";
import ProgressBar from "../../../shared/ProgressBar";
import BottomNavigationBar from "../../../shared/BottomNavigationBar";
import colors from "../../../styles/colors";
import {BASE_URL,getUserID} from '../../../config.js';

const QualityOfLife = () => {
  const [quizData, setQuizData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const navigation = useNavigation();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [counter, setCounter] = useState(0);
  const totalQuestions = 5;
  const [dialogText, setDialogText] = useState("");
  const [submit, setSubmit] = useState(false);
  const [user_id, setUserid] = useState('');

  // Replace 'API_URL' with the actual API URL from where you fetcwh the data
  useEffect(() => {
    if(user_id.length === 0)
    { 
      getUserID();
    }
    navigation.setOptions({
      headerShown: false,
    });
    /*fetch(
      `${BASE_URL}/surveys/get-questionnaires/?user_id=c2b5c9ac-d003-4463-9cd0-8c351c6d13f3&survey_group=BRFSS_MENTAL`
    )
      .then((response) => response.json())
      .then((data) => setQuizData(data.data))
      .catch((error) => console.error(error));*/
  }, []);
  const getUserID = async () => {
    const value = await AsyncStorage.getItem('user_id');
    if (value !== null) {
      
      fetch(
        `${BASE_URL}/surveys/get-questionnaires/?user_id=` + value +`&survey_group=BRFSS_MENTAL`
      )
        .then((response) => response.json())
        .then((data) => setQuizData(data.data))
        .catch((error) => console.error(error));
        setUserid(value);
    }
  };
  const handleNextQuestion = () => {
    const currentQuestion = quizData[currentQuestionIndex];
    const questionId = currentQuestion.question_id.id;

    if (!selectedAnswers[questionId]) {
      // If no option is selected, show the dialog
      setDialogText("Please select an option before proceeding.");
      setShowDialog(true);
    } else {
      // If an option is selected, move to the next question
      setShowDialog(false);
      setSelectedOption(null);
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setCounter((prevCounter) => prevCounter + 1);

      if (currentQuestionIndex === quizData.length - 1) {
        // If it's the last question, submit the quiz
        handleSubmit();
      }
    }
  };

  const handlePreviousQuestion = () => {
    setCounter((prevCounter) => prevCounter - 1);
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleOptionSelect = (questionId, answer) => {
    setSelectedOption(answer);
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleSubmit = async () => {
    const currentQuestion = quizData[currentQuestionIndex];
    const questionId = currentQuestion.question_id.id;

    if (!selectedAnswers[questionId]) {
      // If no option is selected, show the dialog
      setDialogText("Please select an option before submitting.");
      setShowDialog(true);
    } else {
      const requestBody = quizData.map((item) => ({
        user_response: {
          question: item.question_id.question,
          user_choice: selectedAnswers[item.question_id.id],
        },
        is_latest: true,
        elderly: user_id,
        question: item.question_id.id,
      }));

      try {
        const url =
        `${BASE_URL}/surveys/get-user-responses/?user_id=`+user_id;; // Replace with your API endpoint

        const response = await axios.post(url, requestBody);
        setDialogText("Your response has been successfully submitted.");
        setShowDialog(true);
        console.log("Response data:", response.data);
        setSubmit(true);
        // Navigate to the home screen after successful quiz submission
        //handleOkButtonClick();
      } catch (error) {
        console.error("Error:", error);
        // Handle any errors that occurred during the request
      }
    }
  };

  const handleOkButtonClick = () => {
    setShowDialog(false);
    setSelectedOption(null);
    // Navigate to the home screen
    if (submit) {
      navigation.navigate("HomeScreen"); // Replace "Home" with the name of your home screen
    }
  };

  const renderOption = ({ item }) => {
    const questionId = quizData[currentQuestionIndex].question_id.id;
    const isSelected = selectedAnswers[questionId] === item;

    return (
      <TouchableOpacity onPress={() => handleOptionSelect(questionId, item)}>
        <View
          style={[styles.optionContainer, isSelected && styles.selectedOption]}
        >
          <Text style={styles.optionText}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  if (!quizData) {
    return <Text>Loading...</Text>;
  }

  const currentQuestion = quizData[currentQuestionIndex];
  //Render custom header only on web UI
  const renderCustomHeader = isWeb() ? <CustomHeader /> : null;
  const renderBottomNaigation = isWeb() ? null : <BottomNavigationBar />;

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
      {renderCustomHeader}
      <ScrollView showsVerticalScrollIndicator={true}>
        <View style={[styles.container, isWeb() && styles.card]}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <Text style={{ fontSize: 22, fontWeight: "bold" }}>
              Quality of Life and Well-being Assessment
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <Text
              style={{
                color: colors.primaryColor,
                fontSize: 16,
              }}
            >
              Progress
            </Text>
            <Text
              style={{
                color: colors.primaryColor,
                fontSize: 16,
              }}
            >
              {counter}/{totalQuestions} questions
            </Text>
          </View>
          <ProgressBar
            currentQuestion={counter}
            totalQuestions={totalQuestions}
          />
          <Image
            source={require("../../../assets/images/assessment/wellbeing1.png")}
            style={{
              height: 220,
              width: 250,
              alignSelf: "center",
            }}
          />
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <Text
              style={{
                fontSize: 17,
                fontWeight: "bold",
                margin: 10,
              }}
            >
              {counter + 1}. {currentQuestion.question_id.question}
            </Text>
          </View>
          <View style={{ marginTop: 12, alignItems: "center" }}>
            <FlatList
              data={currentQuestion.answers.answer_choices}
              renderItem={renderOption}
              keyExtractor={(item, index) => index.toString()}
            />
          </View>

          <View style={styles.buttonContainer}>
            <Button
              title="Previous"
              onPress={handlePreviousQuestion}
              disabled={currentQuestionIndex === 0}
              color={colors.primaryColorVariant}
            />
            {currentQuestionIndex < quizData.length - 1 ? (
              <Button
                title="Next"
                onPress={handleNextQuestion}
                color={colors.primaryColorVariant}
              />
            ) : (
              <Button
                title="Submit"
                onPress={handleSubmit}
                color={colors.primaryColorVariant}
              />
            )}
          </View>
        </View>
      </ScrollView>

      <Modal visible={showDialog} transparent>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text>{dialogText}</Text>
            <TouchableOpacity
              onPress={() => handleOkButtonClick()}
              style={styles.okButton}
            >
              <Text style={styles.okButtonText}>Ok</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};
const isWeb = () => Platform.OS === "web";
const styles = {
  container: {
    padding: 10,
    backgroundColor: "white",
  },
  questionText: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 16,
    color: colors.primaryColor,
  },
  optionContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1.5,
    borderColor: colors.secondaryColor,
    marginVertical: 10,
    borderRadius: 20,
    width: 350,
  },
  selectedOption: {
    backgroundColor: "#6B9626",
    borderWidth: 2,
    borderColor: "#c3d5a8",
  },
  optionText: {
    padding: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background color
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    borderWidth: 1, // Add border width
    borderColor: "#C0C0C0", // Add border color
  },
  okButton: {
    backgroundColor: colors.primaryColorVariant, // Customize the button background color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 15,
  },
  okButtonText: {
    color: "white", // Customize the button text color
    fontSize: 16,
  },
  card: {
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    marginHorizontal: 150,
    marginVertical: 40,
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 7,
  },
};

export default QualityOfLife;
