import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  FlatList,
  Dimensions,
  Platform,
} from "react-native";
import React, { useState, useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import MealCalendar from "./MealCalendar";
import MenuChoiceForm from "./MenuChoiceForm";
import CustomHeader from "../../../shared/CustomHeader";
import BottomNavigationBar from "../../../shared/BottomNavigationBar";
import CalendarWeekSelection from "./CalendarWeekSelection";

const MealProviderOption = () => {
  const navigation = useNavigation();

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, []);

  const styles = StyleSheet.create({
    container: {
      padding: 10,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    },
    assessmentStyle: {
      margin: 15,
      backgroundColor: "white",
      padding: 20,
      borderRadius: 15,
      borderWidth: 1,
      borderColor: "#C0C0C0",
      alignSelf: "center",
      elevation: 5, // This adds the shadow to the component (Android)
      shadowColor: "black", // Shadow color (iOS)
      shadowOffset: { width: 0, height: 2 }, // Shadow offset (iOS)
      shadowOpacity: 0.3, // Shadow opacity (iOS)
      shadowRadius: 4, // Shadow blur radius (iOS)
    },
    image: {
      width: 300,
      height: 250,
      alignItems: "center",
      justifyContent: "space-evenly",
    },

    text: {
      textAlign: "center",
      marginTop: 10,
      fontWeight: "bold",
      fontSize: 18,
    },
  });
  const isWeb = () => Platform.OS === "web";
  //Render custom header only on web UI
  const renderCustomHeader = isWeb() ? <CustomHeader /> : null;
  const renderBottomNaigation = isWeb() ? null : <BottomNavigationBar />;

  return (
    <View style={{ flex: 1 }}>
      {renderCustomHeader}
      <View style={styles.container}>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <Pressable
            style={styles.assessmentStyle}
            onPress={() => navigation.navigate("MenuChoiceForm")}
          >
            <Image
              source={require("../../../assets/images/meal/meal-choices.png")}
              style={styles.image}
            />
            <Text style={styles.text}>Meal Choices</Text>
          </Pressable>

          <Pressable
            style={styles.assessmentStyle}
            onPress={() => navigation.navigate("CalendarWeekSelection")}
          >
            <Image
              source={require("../../../assets/images/meal/meal-cal.png")}
              style={styles.image}
            />
            <Text style={styles.text}>Meal Calendar</Text>
          </Pressable>
        </ScrollView>
      </View>
      {renderBottomNaigation}
    </View>
  );
};

export default MealProviderOption;
