import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  ScrollView,
  Platform,
} from "react-native";
import React, { useLayoutEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { SafeAreaView } from "react-native";

const Groceries = () => {
  const navigation = useNavigation();

  const isWeb = Platform.OS === "web"; // Check if the platform is web

  const styles = StyleSheet.create({
    container: {
      margin: 20,
      backgroundColor: "white",
      padding: 20,
      borderRadius: 15,
      borderWidth: 1.5,
      borderColor: "#C0C0C0",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      elevation: 5, // This adds the shadow to the component (Android)
      shadowColor: "black", // Shadow color (iOS)
      shadowOffset: { width: 0, height: 2 }, // Shadow offset (iOS)
      shadowOpacity: 0.3, // Shadow opacity (iOS)
      shadowRadius: 4, // Shadow blur radius (iOS)
    },
    image: {
      width: 250,
      height: 220,
      alignContent: "center",
      justifyContent: "center",
    },

    assessmentStyle: {
      marginVertical: 10,
      marginHorizontal: 7,
      backgroundColor: "white",
      padding: 20,
      borderRadius: 15,
      borderWidth: 1.5,
      borderColor: "#C0C0C0",
      alignSelf: "center",
      elevation: 5, // This adds the shadow to the component (Android)
      shadowColor: "black", // Shadow color (iOS)
      shadowOffset: { width: 0, height: 2 }, // Shadow offset (iOS)
      shadowOpacity: 0.3, // Shadow opacity (iOS)
      shadowRadius: 4, // Shadow blur radius (iOS)
    },

    webAssessmentStyle: {
      width: "40%",
      marginVertical: 10,
      backgroundColor: "white",
      borderRadius: 15,
      borderWidth: 2,
      padding: 15,
      borderColor: "#C0C0C0",
      elevation: 5,
      shadowColor: "#C0C0C0",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.3,
      shadowRadius: 4,
      alignItems: "center", // Center the content horizontally
      justifyContent: "center", // Center the content vertically
      marginHorizontal: 15,
    },
    webContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      paddingHorizontal: 20,
      marginVertical: 10,
      alignItems: "center", // Center the content horizontally
      justifyContent: "center", // Center the content vertically
    },

    webImage: {
      width: "70%",
      height: 280,
      borderRadius: 15,
      padding: 10,
    },
    webText: {
      textAlign: "center",
      marginTop: 10,
      fontWeight: "bold",
      fontSize: 18,
    },
  });

  return (
    <View style={[isWeb && styles.webContainer]}>
      <Pressable
        style={[!isWeb && styles.container, isWeb && styles.webAssessmentStyle]}
      >
        <Image
          source={require("../assets/images/groceries.jpg")}
          style={[!isWeb && styles.image, isWeb && styles.webImage]}
        />
        <Text style={styles.webText}>Groceries</Text>
      </Pressable>

      <Pressable
        style={[!isWeb && styles.container, isWeb && styles.webAssessmentStyle]}
      >
        <Image
          source={require("../assets/images/comingsoon.jpg")}
          style={[!isWeb && styles.image, isWeb && styles.webImage]}
        />
        <Text style={styles.webText}>Coming Soon</Text>
      </Pressable>
    </View>
  );
};

export default Groceries;
