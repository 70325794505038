import React, { useState } from "react";
import { Text, TouchableOpacity } from "react-native";
import { useAuth } from "../../../components/AuthContext";
import DialogBox from "../../../shared/DialogBox";

export default function LogoutButton({ styles }) {
  const { state, signOut } = useAuth();
  const [dialogVisible, setDialogVisible] = useState(false);

  if (!state.user) return null;

  return (
    <>
      <TouchableOpacity
        style={styles.logoutButtonContainer}
        onPress={() => setDialogVisible(true)}
      >
        <Text style={styles.logoutButtonText}>Logout</Text>
      </TouchableOpacity>
      <DialogBox
        isVisible={dialogVisible}
        onClose={() => setDialogVisible(false)}
        text="Are you sure you want to log out?"
        primaryButtonText="Cancel"
        onPrimaryPress={() => setDialogVisible(false)}
        secondaryButtonText="Confirm"
        onSecondaryPress={() => {
          signOut();
          setDialogVisible(false);
        }}
      />
    </>
  );
}
