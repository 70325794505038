const mealData = {
  http_status: 200,
  message: null,
  data: [
    {
      id: 3,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Teriyaki Diced Chicken",
      meal_category: "CHOICE_A",
      day_of_the_week: "MONDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 5,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Beef With Country Gravy",
      meal_category: "CHOICE_A",
      day_of_the_week: "MONDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 6,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Onion Breaded Chicken",
      meal_category: "CHOICE_B",
      day_of_the_week: "MONDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 7,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Coconut Chicken",
      meal_category: "CHOICE_A",
      day_of_the_week: "MONDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 8,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Soy Ginger Beef",
      meal_category: "CHOICE_B",
      day_of_the_week: "MONDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 9,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Salisbury Beef with Brown Gravy",
      meal_category: "CHOICE_A",
      day_of_the_week: "MONDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 10,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Pesto Diced Chicken",
      meal_category: "CHOICE_B",
      day_of_the_week: "MONDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 11,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "BBQ Pork Rib Patty",
      meal_category: "CHOICE_A",
      day_of_the_week: "MONDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 12,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Dijion Herb Chicken Chicken Meatballs",
      meal_category: "CHOICE_B",
      day_of_the_week: "MONDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 13,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Beef Burgundy",
      meal_category: "CHOICE_B",
      day_of_the_week: "MONDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 14,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Ham with Red Beans and Brown Rice",
      meal_category: "CHOICE_A",
      day_of_the_week: "MONDAY",
      week: "WEEK7",
      provider: 2,
    },
    {
      id: 16,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Meatloaf with Brown Gravy",
      meal_category: "CHOICE_A",
      day_of_the_week: "MONDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 17,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Mozzarella Chicken",
      meal_category: "CHOICE_B",
      day_of_the_week: "MONDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 18,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Bechamel Chicken Meatballs",
      meal_category: "CHOICE_A",
      day_of_the_week: "MONDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 15,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Chicken Etouffee",
      meal_category: "CHOICE_B",
      day_of_the_week: "MONDAY",
      week: "WEEK7",
      provider: 2,
    },
    {
      id: 4,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Thai Chili Beef",
      meal_category: "CHOICE_B",
      day_of_the_week: "MONDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 22,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Heavenly Ham",
      meal_category: "CHOICE_B",
      day_of_the_week: "TUESDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 20,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Turkey Brown Rice Casserole",
      meal_category: "CHOICE_B",
      day_of_the_week: "TUESDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 19,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Cheeseburger Macaroni",
      meal_category: "CHOICE_A",
      day_of_the_week: "TUESDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 21,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Turkey Pot Pie",
      meal_category: "CHOICE_A",
      day_of_the_week: "TUESDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 23,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Turkey and White Beans",
      meal_category: "CHOICE_A",
      day_of_the_week: "TUESDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 24,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Beef Stroganoff",
      meal_category: "CHOICE_B",
      day_of_the_week: "TUESDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 25,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Moroccan Chicken Stew",
      meal_category: "CHOICE_A",
      day_of_the_week: "TUESDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 26,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Ground Beef Stew",
      meal_category: "CHOICE_B",
      day_of_the_week: "TUESDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 27,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Baked Chicken with Gravy",
      meal_category: "CHOICE_A",
      day_of_the_week: "TUESDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 28,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Beef with Brown Gravy",
      meal_category: "CHOICE_B",
      day_of_the_week: "TUESDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 29,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Citrus Pork",
      meal_category: "CHOICE_A",
      day_of_the_week: "TUESDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 30,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Honey Baked Chicken",
      meal_category: "CHOICE_B",
      day_of_the_week: "TUESDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 31,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Beef With Mushroom Gravy",
      meal_category: "CHOICE_A",
      day_of_the_week: "TUESDAY",
      week: "WEEK7",
      provider: 2,
    },
    {
      id: 32,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Chicken Picatta",
      meal_category: "CHOICE_B",
      day_of_the_week: "TUESDAY",
      week: "WEEK7",
      provider: 2,
    },
    {
      id: 33,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Tofu Fried Rice",
      meal_category: "CHOICE_A",
      day_of_the_week: "TUESDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 34,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Five Spice Chicken",
      meal_category: "CHOICE_B",
      day_of_the_week: "TUESDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 47,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Black Bean Chicken",
      meal_category: "CHOICE_A",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK7",
      provider: 2,
    },
    {
      id: 35,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Lemon Pepper Chicken",
      meal_category: "CHOICE_A",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 36,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Pork Loin with Mushroom Gravy",
      meal_category: "CHOICE_B",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 37,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Hunan Style Tofu",
      meal_category: "CHOICE_A",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 38,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Curry Chicken",
      meal_category: "CHOICE_B",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 39,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Pork Carnitas",
      meal_category: "CHOICE_A",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 40,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Chicken Fajita",
      meal_category: "CHOICE_B",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 41,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Lemon Pepper Fish",
      meal_category: "CHOICE_A",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 42,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "BBQ Pork Rib Patty",
      meal_category: "CHOICE_B",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 44,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Homestyle Pork Roast",
      meal_category: "CHOICE_B",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 43,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Fish Nuggets",
      meal_category: "CHOICE_A",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 45,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Hamburger Patty",
      meal_category: "CHOICE_A",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 46,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Breaded Chicken Patty",
      meal_category: "CHOICE_B",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 48,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Peking Pork",
      meal_category: "CHOICE_B",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK7",
      provider: 2,
    },
    {
      id: 49,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Gourmet Turkey Salad",
      meal_category: "CHOICE_A",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 50,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Salmon Pasta Salad",
      meal_category: "CHOICE_B",
      day_of_the_week: "WEDNESDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 57,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Pork Loin with Onion Gravy",
      meal_category: "CHOICE_A",
      day_of_the_week: "THURSDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 51,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "BBQ Diced Turkey",
      meal_category: "CHOICE_A",
      day_of_the_week: "THURSDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 52,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Sloppy Joe",
      meal_category: "CHOICE_B",
      day_of_the_week: "THURSDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 53,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Chicken Marsala",
      meal_category: "CHOICE_A",
      day_of_the_week: "THURSDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 56,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Swiss Steak",
      meal_category: "CHOICE_B",
      day_of_the_week: "THURSDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 58,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Breaded Chicken With Gravy",
      meal_category: "CHOICE_B",
      day_of_the_week: "THURSDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 59,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Beef Chili With Beans",
      meal_category: "CHOICE_A",
      day_of_the_week: "THURSDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 60,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Chicken Chili",
      meal_category: "CHOICE_B",
      day_of_the_week: "THURSDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 61,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Greek Chicken",
      meal_category: "CHOICE_A",
      day_of_the_week: "THURSDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 62,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Sesame Mustard Vegballs",
      meal_category: "CHOICE_B",
      day_of_the_week: "THURSDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 63,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Sliced Turkey Breast and Swiss Cheese",
      meal_category: "CHOICE_A",
      day_of_the_week: "THURSDAY",
      week: "WEEK7",
      provider: 2,
    },
    {
      id: 64,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Sliced Ham and Swiss Cheese",
      meal_category: "CHOICE_B",
      day_of_the_week: "THURSDAY",
      week: "WEEK7",
      provider: 2,
    },
    {
      id: 65,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Creamy Paprika Chicken",
      meal_category: "CHOICE_A",
      day_of_the_week: "THURSDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 66,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Rosemary Butter Sliced Ham",
      meal_category: "CHOICE_B",
      day_of_the_week: "THURSDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 54,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Beef with Brown Gravy",
      meal_category: "CHOICE_B",
      day_of_the_week: "THURSDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 55,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Lemon Caper Chicken",
      meal_category: "CHOICE_A",
      day_of_the_week: "THURSDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 67,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Spaghetti Torte",
      meal_category: "CHOICE_A",
      day_of_the_week: "FRIDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 68,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Chicken Tetrazzini",
      meal_category: "CHOICE_B",
      day_of_the_week: "FRIDAY",
      week: "WEEK1",
      provider: 2,
    },
    {
      id: 69,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Cheese Omelet",
      meal_category: "CHOICE_A",
      day_of_the_week: "FRIDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 80,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Tuna Macaroni and Cheese",
      meal_category: "CHOICE_B",
      day_of_the_week: "FRIDAY",
      week: "WEEK7",
      provider: 2,
    },
    {
      id: 81,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Cajun Fish",
      meal_category: "CHOICE_A",
      day_of_the_week: "FRIDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 82,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Sausage and Red Beans",
      meal_category: "CHOICE_B",
      day_of_the_week: "FRIDAY",
      week: "WEEK8",
      provider: 2,
    },
    {
      id: 70,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Apricot Chicken",
      meal_category: "CHOICE_B",
      day_of_the_week: "FRIDAY",
      week: "WEEK2",
      provider: 2,
    },
    {
      id: 71,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Egg Salad",
      meal_category: "CHOICE_A",
      day_of_the_week: "FRIDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 72,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Yogurt Dill Chicken Salad",
      meal_category: "CHOICE_B",
      day_of_the_week: "FRIDAY",
      week: "WEEK3",
      provider: 2,
    },
    {
      id: 73,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Balsamic Brown Sugar Chicken",
      meal_category: "CHOICE_A",
      day_of_the_week: "FRIDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 74,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Three Cheese Ziti",
      meal_category: "CHOICE_B",
      day_of_the_week: "FRIDAY",
      week: "WEEK4",
      provider: 2,
    },
    {
      id: 75,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Spinach Macaroni and Cheese",
      meal_category: "CHOICE_A",
      day_of_the_week: "FRIDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 76,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Chicken Alfredo",
      meal_category: "CHOICE_B",
      day_of_the_week: "FRIDAY",
      week: "WEEK5",
      provider: 2,
    },
    {
      id: 77,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Turkey Pasta Bolognese",
      meal_category: "CHOICE_A",
      day_of_the_week: "FRIDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 78,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Six Bean Stew",
      meal_category: "CHOICE_B",
      day_of_the_week: "FRIDAY",
      week: "WEEK6",
      provider: 2,
    },
    {
      id: 79,
      created_date: "2022-12-11T04:36:47.039000Z",
      modified_date: "2022-12-11T04:36:47.039000Z",
      meal_name: "Beef Enchilada Pie",
      meal_category: "CHOICE_A",
      day_of_the_week: "FRIDAY",
      week: "WEEK7",
      provider: 2,
    },
  ],
  success: true,
  code: null,
};

export default mealData;
