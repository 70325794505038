import { StyleSheet, Pressable, Image, Text, View } from "react-native";

const ThankYou = ({ navigation }) => {
  // Naviage back to the Route Sheet when button pressed
  const handleReturnPress = (clientName) => {
    navigation.navigate("RouteSheet");
  };

  return (
    <View style={styles.main}>
      <View style={styles.container}>
        <Image
          style={styles.confetti}
          source={require("../../assets/images/Volunteer/confetti.png")}
        />
      </View>
      <View style={styles.containerText}>
        <Text style={styles.thankYou}>Thank you!</Text>
        <Text style={styles.yourWellnessCheck1}>
          Your wellness check survey has been successfully completed. Thank you
          for volunteering for Meals on Wheels!
        </Text>
        <Pressable onPress={handleReturnPress}>
          <Text style={styles.returnToRoute1}>Return to Route</Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    marginBottom: "25%",
    flexDirection: "col",
    justifyContent: "center",
    height: "100%",
  },
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  containerText: {
    left: "10%",
    maxWidth: "80%",
  },
  thankYou: {
    fontSize: 36,
    fontWeight: "500",
    // fontFamily: "Inter-Medium",
    color: "#000",
    textAlign: "left",
    marginBottom: 16,
  },
  yourWellnessCheck1: {
    fontSize: 18,
    //  fontFamily: "Inter-Regular",
    color: "#000",
    textAlign: "left",
  },
  returnToRoute1: {
    fontSize: 24,
    marginTop: 19,
    // fontFamily: "Inter-Regular",
    color: "#00b8d1",
    textAlign: "left",
    width: 200,
  },
  confetti: {
    alignSelf: "flex-end",
  },
});

export default ThankYou;
