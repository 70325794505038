import { Text, View } from "react-native";
import React, { Component } from "react";

export class FavoritesScreen extends Component {
  render() {
    return (
      <View>
        <Text>FavoritesScreen</Text>
      </View>
    );
  }
}

export default FavoritesScreen;
