import React from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
  StyleSheet,
  Button,
  Dimensions,
} from "react-native";
import colors from "../../styles/colors";

const { width, height } = Dimensions.get("window");

const ClientButton = ({
  address,
  clientName,
  delivery,
  delivered,
  handleButtonPressMap,
  hotColor,
  coldBag,
  styles,
  isAttempted,
}) => {
  // Function to handle button press and open Google Maps and navigate to WellnessCheck
  const handleButtonPress = () => {
    handleButtonPressMap(clientName, address);
  };

  return (
    <TouchableOpacity
      style={
        delivery === "Delivered"
          ? clientStyles.buttonFlatDisable
          : clientStyles.buttonFlat
      }
      onPress={handleButtonPress}
      disabled={delivered}
    >
      <View style={clientStyles.buttonContent}>
        {/* Depending on delivery status, put Checkmark image or X image */}
        {delivery === "Delivered" ? (
          <Image
            source={require("../../assets/images/Volunteer/check_mark1.png")}
            style={clientStyles.exclamationImage}
          />
        ) : isAttempted ? (
          <Image
            source={require("../../assets/images/Volunteer/exclamation_yellow1.png")}
            style={clientStyles.exclamationImage}
          />
        ) : (
          <Image
            source={require("../../assets/images/Volunteer/exclamation_point.png")}
            style={clientStyles.exclamationImage}
          />
        )}
        <View style={clientStyles.container}>
          <View style={clientStyles.leftColumn}>
            <Text style={clientStyles.clientNameText}>{clientName}</Text>
            <Text style={clientStyles.addressText}>Address: {address}</Text>
          </View>
          <View style={clientStyles.rightColumn}>
            <Text style={clientStyles.hotColdText}>Hot Meal: {hotColor}</Text>
            <Text style={clientStyles.hotColdText}>Cold Bags: {coldBag}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const clientStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-start", // Align items to start
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  buttonContent: {
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
  },
  buttonFlat: {
    width: width * 0.8,
    backgroundColor: "#E6E7E8",
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
  },
  buttonFlatDisable: {
    width: width * 0.8,
    backgroundColor: "#b6b9bc",
    borderRadius: 10,
    flexDirection: "row",
    //  justifyContent: "center",
    alignItems: "center",
    marginVertical: 5,
  },
  exclamationImage: {
    width: 30,
    height: 30,
    marginTop: "2%",
    marginRight: "auto",
    marginLeft: "10%",
    padding: 10,
  },
  leftColumn: {
    // flex: 3, // Adjusted flex value
    marginRight: "5%", // Add margin for separation
    flexWrap: "wrap",
    flexShrink: 1,
    width: 380,
  },
  rightColumn: {
    // flex: 4, // Adjusted flex value
    alignItems: "flex-start", // Align items to start
    marginLeft: "10%", // Add margin for separation
    flexWrap: "wrap",
    flexShrink: 1,
    width: 380,
  },
  clientNameText: {
    marginBottom: 5,
    fontSize: 20,
    color: colors.primaryColor,
  },
  addressText: {
    marginBottom: 5,
    fontSize: 20,
    color: colors.primaryColor,
  },
  hotColdText: {
    marginBottom: 5,
    fontSize: 20,
    color: colors.primaryColor,
  },
});

export default ClientButton;
