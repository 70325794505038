import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import { Keyboard } from "react-native";
import DeliveredPopup from "./DeliveredPopup";
import NeighborCheckIn from "./NeighborCheckIn";
import CallPopup from "./CallPopup";
import DataContext, { useData } from "../DataContext";
import TextPopup from "./TextPopup";
import { useAuth } from "../AuthContext";
import { BASE_URL } from "../../config";
import { getItem } from "expo-secure-store";
import {
  getItemAsync,
  setItemAsync,
  deleteItemAsync,
} from "../../utils/storage";
import DialogBox from "../../shared/DialogBox";
import colors from "../../styles/colors";

const ClientDetailsCard = ({ selectedClient }) => {
  return (
    <View style={styles.cardContainer}>
      {/*<Text style={styles.cardTitle}>Client Details</Text>*/}
      <View style={styles.detailsContainer}>
        <View style={styles.detailRow}>
          <Text style={styles.detailLabel}>Address:</Text>
          <Text style={styles.detailText}>{selectedClient?.address}</Text>
        </View>
        <View style={styles.detailRow}>
          <Text style={styles.detailLabel}>Hot meal:</Text>
          <Text style={styles.detailText}>{selectedClient?.hotColor}</Text>
        </View>
        <View style={styles.detailRow}>
          <Text style={styles.detailLabel}>Cold bag:</Text>
          <Text style={styles.detailText}>{selectedClient?.coldBag}</Text>
        </View>
        <View style={styles.detailRow}>
          <Text style={styles.detailLabel}>Phone:</Text>
          <Text style={styles.detailText}>
            {selectedClient?.phone ? selectedClient.phone : "Not available"}
          </Text>
        </View>
        <View style={styles.detailRow}>
          <Text style={styles.detailLabel}>Delivery Instructions:</Text>
          <Text style={styles.detailText}>
            {selectedClient?.delivery_instructions
              ? selectedClient.delivery_instructions
              : "None"}
          </Text>
        </View>
      </View>
    </View>
  );
};

const WellnessCheck = ({ route, navigation }) => {
  const [selectedButtonsDelivery, setSelectedButtonsDelivery] = useState([
    0, 0,
  ]);
  const [showNeighborCheckIn, setShowNeighborCheckIn] = useState(false);
  const [neighborCheckInSubmitted, setNeighborCheckInSubmitted] =
    useState(false);
  const [selectedButtonsSurvey, setSelectedButtonsSurvey] = useState([0, 0]);
  const [isDeliveredPopupVisible, setDeliveredPopupVisible] = useState(false);
  const [isPopupVisibleCall, setPopupVisibleCall] = useState(false);
  const [isTextPopupVisible, setTextPopupVisible] = useState(false);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [deliveryCode, setDeliveryCode] = useState("null");
  const [selectedSurveyCode, setSelectedSurveyCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("     ");
  const [showDialog, setShowDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const { selectedClient } = route.params;
  // const { clientsData, setClientsData, ipAddress } = useContext(DataContext);
  const { dataState, setClientsData, updateDeliveryStatus } = useData();
  const clientsData = dataState.clientsData;
  const textPopupInputRef = useRef(null);
  const { state } = useAuth();
  const [volunteerDelivery, setVolunteerDelivery] = useState(null);

  const CODE_TO_DELIVERED_TO = {
    CLI: "CLIENT",
    RES: "RESIDENT_IN_HOME",
    NEI: "NEIGHBOUR",
    COW: "COWORKER",
    REF: "REFRIGERATOR",
  };

  const SURVEY_CODE_TO_TEXT = {
    NOI: "NO_ISSUES",
    REQ: "REQUIRE_FOLLOWUP",
  };

  /**
   * Handles the text input in the TextPopup component and updates the
   * additionalFeedback state.
   */
  const handleSaveText = (text) => {
    setAdditionalFeedback(text);
  };

  /**
   * Handles the press on the delivery or survey boxes, updating the selected
   * buttons and managing popups.
   */
  const handleBoxPress = (boxNumber, row) => {
    if (row === "delivery") {
      setSelectedButtonsDelivery((prevSelectedButtons) =>
        prevSelectedButtons.map((val, index) => (index === boxNumber ? 1 : 0))
      );
      if (boxNumber === 0) {
        setDeliveryCode("null");
        setDeliveredPopupVisible(true);
        setPopupVisibleCall(false);
      } else {
        setDeliveryCode("null");
        setPopupVisibleCall(true);
        setDeliveredPopupVisible(false);
      }
    } else if (row === "survey") {
      setSelectedButtonsSurvey((prevSelectedButtons) =>
        prevSelectedButtons.map((val, index) => (index === boxNumber ? 1 : 0))
      );

      setSelectedSurveyCode(boxNumber === 0 ? "NOI" : "REQ");
      if (boxNumber === 0) {
        if (!neighborCheckInSubmitted) {
          setShowNeighborCheckIn(true);
        } else {
          setShowDialog(true);
          setDialogText("Neighbor check-in already submitted.");
        }
      }
    }
  };

  /**
   * Handles the completion of the wellness check, updating client data and
   * navigating to the ThankYou screen (resets all old states). Ensures all
   * fields are completed before the user can submit the survey.
   */
  const handleFinishCheck = async () => {
    if (selectedButtonsDelivery[0] === 1 && deliveryCode === "null") {
      setErrorMessage("Delivered to... incomplete.");
      return;
    } else if (
      selectedButtonsDelivery[0] === 0 &&
      selectedButtonsDelivery[1] === 0
    ) {
      setErrorMessage("Select Delivery option.");
      return;
    } else if (
      selectedButtonsSurvey[0] === 0 &&
      selectedButtonsSurvey[1] === 0
    ) {
      setErrorMessage("Select Survey option.");
      return;
    }

    const clients = Object.values(dataState.clientsData);
    const clientIndex = clients.findIndex(
      (client) => client.delivery_id === selectedClient.delivery_id
    );

    if (clientIndex !== -1) {
      const updatedClients = [...clients];
      if (deliveryCode == "NOT") {
        // updatedClients[clientIndex].delivery = "Attempted";
        updateDeliveryStatus(selectedClient.delivery_id, false);
      } else {
        // updatedClients[clientIndex].delivery = "Delivered";
        updateDeliveryStatus(selectedClient.delivery_id, true);
      }
      updatedClients[clientIndex].isAttempted = true;
      console.log("Selected client:", selectedClient);
      console.log("Updating client:", updatedClients[clientIndex]);
      console.log("Updating isAttempted:", selectedClient.isAttempted); // Log the client being updated
      // setClientsData(updatedClients);
    } else {
      // console.log('Client not found:', clientName); // Log if the client wasn't found
    }

    // await fetch(
    //   `http://${ipAddress}:000/put_wellness_check/Abhijit%20Zhu/${clientName}
    //    /${deliveryCode}/${selectedSurveyCode}/${additionalFeedback}`
    // );

    const volunteer_delivery = JSON.parse(
      await getItemAsync(selectedClient?.delivery_id)
    );
    const currentTime = new Date();
    volunteer_delivery.end_time = currentTime;

    const volunteer_survey = {
      volunteer_first_name: state.user.first_name,
      volunteer_last_name: state.user.last_name,
      neighbor_name: selectedClient?.clientName,
      date_of_delivery: `${currentTime.getUTCFullYear()}-${
        currentTime.getUTCMonth() + 1
      }-${currentTime.getUTCDate()}`,
      delivery_status: deliveryCode === "NOT" ? "NOT_DELIVERED" : "DELIVERED", // Delivered or Not Delivered
      delivererd_to: CODE_TO_DELIVERED_TO[deliveryCode],
      survey_status: SURVEY_CODE_TO_TEXT[selectedSurveyCode], // NOI or REQ
      feedback: additionalFeedback,
    };

    // Post the volunteer delivery info to the volunteer-delivery API endpoint
    fetch(`${BASE_URL}/mowdelivery/volunteer-delivery/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(volunteer_delivery),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => {
        console.error("Error:", error);
      });

    // Post the volunteer survey to the volunteer-survey API endpoint
    fetch(`${BASE_URL}/mowdelivery/volunteer-survey/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(volunteer_survey),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => {
        console.error("Error:", error);
      });

    deleteItemAsync(selectedClient?.delivery_id);

    setErrorMessage("      ");
    navigation.navigate("ThankYou");
    setSelectedButtonsDelivery([0, 0]);
    setSelectedButtonsSurvey([0, 0]);
    setSelectedSurveyCode("");
  };

  // useEffect(() => {
  //   console.log("DATA UPDATED: ", clientsData);
  // }, [clientsData]);

  /**
   * Opens the TextPopup component to allow users to input additional feedback.
   */
  const handleTextPopupOpen = () => {
    setTextPopupVisible(true);
  };

  /**
   * Handles the selection in the DeliveredPopup component and updates the
   * deliveryCode state.
   */
  const handleSelectionDeliveredPopup = (code) => {
    setDeliveryCode(code);
  };

  /**
   * Closes the DeliveredPopup component.
   */
  const handleCloseDeliveredPopup = () => {
    setDeliveredPopupVisible(false);
  };

  /**
   * Closes the CallPopup component, sets the deliveryCode to "NOT" indicating
   * not delivered.
   */
  const handleCloseCallPopup = () => {
    setPopupVisibleCall(false);
    /* CODE: NOT DELIVERED */
    setDeliveryCode("NOT");
  };

  /**
   * Closes the TextPopup component.
   */
  const handleCloseTextPopup = () => {
    setTextPopupVisible(false);
  };

  /**
   * Renders the delivery or survey buttons based on the provided data.
   */
  const renderButtons = (buttonsData, selectedButtons, row) => {
    const buttons = [];

    for (let i = 0; i < buttonsData.length; i++) {
      const isSelected = selectedButtons[i] === 1;

      buttons.push(
        <TouchableOpacity
          onPress={() => handleBoxPress(i, row)}
          key={i}
          style={[
            styles.box,
            isSelected
              ? i % 2 === 0
                ? styles.greenBox
                : styles.redBox
              : styles.whiteBox,
          ]}
        >
          <Image
            source={buttonsData[i].image}
            style={styles.buttonImage}
            resizeMode="contain"
          />
          <Text style={styles.buttonInText}>{buttonsData[i].text}</Text>
        </TouchableOpacity>
      );
    }

    return buttons;
  };

  /**
   * Array for delivery buttons.
   */
  const deliveryButtons = [
    {
      text: "Delivered to...",
      image: require("../../assets/images/Volunteer/emoji_waving_hand.png"),
    },
    {
      text: "Not delivered",
      image: require("../../assets/images/Volunteer/icon_sensor_door.png"),
    },
  ];

  /**
   * Array for survey buttons.
   */
  const surveyButtons = [
    {
      text: "Neighbor check-in",
      image: require("../../assets/images/Volunteer/icon_thumbs_up.png"),
    },
    {
      text: "Requires follow up",
      image: require("../../assets/images/Volunteer/icon_alert_error.png"),
    },
  ];

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.container}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.name}>{selectedClient?.clientName}</Text>
            <View style={styles.listContainer}>
              <Text style={styles.title}>Neighbor Check In</Text>
              <Image
                source={require("../../assets/images/Volunteer/check_mark1.png")}
                style={styles.exclamationImage}
              />
            </View>
            <ClientDetailsCard selectedClient={selectedClient} />
          </View>
          <View style={styles.content}>
            <Text style={styles.interiorText}>Delivery</Text>
            <View style={styles.grid}>
              {renderButtons(
                deliveryButtons,
                selectedButtonsDelivery,
                "delivery"
              )}
            </View>
            <Text style={styles.interiorText}>Survey</Text>
            <View style={styles.grid}>
              {renderButtons(surveyButtons, selectedButtonsSurvey, "survey")}
            </View>

            <Text style={styles.interiorText}>Additional Feedback</Text>

            <View style={styles.textBox}>
              <TouchableOpacity
                onPress={handleTextPopupOpen}
                ref={textPopupInputRef}
                style={styles.textInput}
                editable={false}
              >
                <Text style={styles.textText}>{additionalFeedback}</Text>
              </TouchableOpacity>
            </View>

            {errorMessage !== "" && (
              <Text style={styles.errorText}>{errorMessage}</Text>
            )}

            <TouchableOpacity
              style={styles.button}
              onPress={handleFinishCheck}
            >
              <Text style={styles.buttonText}>Finish Check</Text>
            </TouchableOpacity>
          </View>

          <NeighborCheckIn
            isVisible={showNeighborCheckIn}
            onClose={() => {
              setShowNeighborCheckIn(false);
            }}
            submitAction={setNeighborCheckInSubmitted}
            neighborName={selectedClient?.clientName}
          />
          <DeliveredPopup
            isVisible={isDeliveredPopupVisible}
            onSelection={handleSelectionDeliveredPopup}
            onClose={handleCloseDeliveredPopup}
          />
          <CallPopup
            isVisible={isPopupVisibleCall}
            onClose={handleCloseCallPopup}
          />
          <TextPopup
            isVisible={isTextPopupVisible}
            onSave={handleSaveText}
            onClose={handleCloseTextPopup}
          />
          <DialogBox
            isVisible={showDialog}
            onClose={() => {
              setShowDialog(false);
            }}
            text={dialogText}
            primaryButtonText="Close"
            onPrimaryPress={() => setShowDialog(false)}
          />
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};

/**
 * Style sheet.
 */
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#E6E7E8",
  },
  listContainer: {
    flex: 1,
    flexDirection: "row",
  },
  header: {
    paddingTop: "22%",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "5%",
    color: "#000000",
  },
  name: {
    color: colors.primaryColor,
    fontSize: 24,
    fontStyle: "normal",
    //  paddingLeft: "5%",
  },
  title: {
    color: colors.primaryColor,
    fontSize: 36,
    fontStyle: "normal",
    // paddingLeft: "5%",
    paddingBottom: "1%",
  },
  other: {
    color: colors.primaryColor,
    fontSize: 18,
    fontStyle: "normal",
    paddingLeft: "5%",
  },
  interiorText: {
    fontSize: 24,
    paddingLeft: "5%",
    paddingBottom: "2%",
  },
  content: {
    backgroundColor: "white",
    padding: "5%",
  },
  grid: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "92%",
    alignSelf: "center",
  },
  box: {
    width: "48%",
    height: 93,
    borderRadius: 10,
    borderColor: "#A0A0A0",
    borderWidth: 1,
    marginBottom: "5%",
    alignItems: "center",
    justifyContent: "center",
  },
  greenBox: {
    backgroundColor: "#C4D82E",
  },
  redBox: {
    backgroundColor: "#EF4925",
  },
  whiteBox: {
    backgroundColor: "#FFFFFF",
  },
  textBox: {
    width: "92%",
    height: "25%",
    backgroundColor: "#E6E7E8",
    borderRadius: 10,
    marginBottom: "3%",
    alignSelf: "center",
  },
  textInput: {
    // fontFamily: "Inter-Regular",
    paddingLeft: "4%",
    width: "100%",
    height: "100%",
    borderRadius: 10,
    color: "#000",
  },
  button: {
    width: "93%",
    height: "9%",
    backgroundColor: "#00B8D1",
    borderRadius: 40,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  buttonText: {
    fontSize: 32,
    // fontFamily: "Inter-Bold",
    fontWeight: "700",
    color: "white",
  },
  buttonImage: {
    flex: 1,
    width: "35%",
    justifyContent: "center",
    alignSelf: "center",
  },
  buttonInText: {
    fontSize: 14,
    paddingBottom: "6%",
    justifyContent: "center",
    alignSelf: "center",
  },
  keyboardAvoidingContent: {
    flex: 1,
  },
  textText: {
    paddingTop: "4%",
  },
  errorText: {
    color: "#FF0000",
    //fontFamily: "Inter-Regular",
    fontSize: 16,
    textAlign: "center",
    marginBottom: "3%",
  },
  exclamationImage: {
    // position: "absolute",
    width: 30,
    height: 30,
    top: "7%",
  },
  cardContainer: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    elevation: 5,
  },
  cardTitle: {
    fontSize: 22,
    fontWeight: "bold",
    marginBottom: 10,
    color: colors.primaryColor,
    alignSelf: "center",
    flexDirection: "row",
  },
  detailsContainer: {
    flexDirection: "column",
  },
  detailRow: {
    flexDirection: "row",
    marginBottom: 10,
  },
  detailLabel: {
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 10,
    color: colors.primaryColor,
  },
  detailText: {
    color: colors.primaryColor,
    fontSize: 18,
    flex: 1,
  },
});

export default WellnessCheck;
