import React, { useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Keyboard,
} from "react-native";

const TextPopup = ({ isVisible, onClose, onSave }) => {
  // State to manage the input text in the TextInput component.
  const [inputText, setInputText] = useState("");
  const inputRef = useRef(null);

  /**
   * Handles the save action, invokes the onSave callback, closes the popup, and
   * dismisses the keyboard.
   */
  const handleSaveText = () => {
    onSave(inputText);
    onClose();
    Keyboard.dismiss();
  };

  /**
   * Handles the close action, closes the popup, and dismisses the keyboard.
   */
  const handleCloseText = () => {
    onClose();
    Keyboard.dismiss();
  };

  return (
    isVisible && (
      <View style={styles.popupContainer} pointerEvents="auto">
        <View style={styles.popup} pointerEvents="auto">
          <Text style={styles.header}>Additional Feedback</Text>
          <TouchableOpacity activeOpacity={1} onPress={() => inputRef.current.focus()}>
            <TextInput
              ref={inputRef}
              editable
              style={styles.textInput}
              multiline
              placeholder="Optional"
              value={inputText}
              onChangeText={setInputText}
              autoFocus={true}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={handleSaveText} style={styles.saveButton}>
            <Text style={styles.saveButtonText}>Save</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={handleCloseText}
            style={styles.closeButton}
          >
            <Text style={styles.closeButtonText}>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  );
};

/**
 * Style sheet.
 */
const styles = StyleSheet.create({
  popupContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    alignItems: "center",
    justifyContent: "center",
  },
  popup: {
    width: "86%",
    backgroundColor: "white",
    borderRadius: 10,
    padding: "6%",
  },
  header: {
    color: "#000",
    // fontFamily: "Inter-Medium",
    fontSize: 24,
  },
  textInput: {
    height: 100,
    borderColor: "gray",
    borderWidth: 1,
    marginVertical: 10,
    paddingHorizontal: 10,
    //  fontFamily: "Inter-Regular",
    borderRadius: 10,
  },
  saveButton: {
    backgroundColor: "#00B8D1",
    borderRadius: 5,
    padding: 10,
    alignItems: "center",
  },
  saveButtonText: {
    color: "white",
    fontSize: 20,
    //fontFamily: "Inter-Medium",
  },
  closeButton: {
    marginVertical: 10,
  },
  closeButtonText: {
    fontSize: 20,
    color: "#000",
    // fontFamily: "Inter-Regular",
  },
});

export default TextPopup;
