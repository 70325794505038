import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Linking,
} from "react-native";

const CallPopup = ({ isVisible, onClose }) => {
  // Phone number to be dialed when the "Call" button is pressed.
  const phoneNumber = "512-435-3095";

  /**
   * Handles the press event on the "Call" button, opens the phone dialer.
   */
  const handleCallPress = () => {
    const phoneUrl = `tel:${phoneNumber}`;
    Linking.openURL(phoneUrl);
  };

  return (
    isVisible && (
      <View style={styles.popupContainer}>
        <View style={styles.popup}>
          <TouchableOpacity onPress={handleCallPress}>
            <Text style={styles.callText}>Call {phoneNumber}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onClose} style={styles.closeButton}>
            <Text style={styles.closeButtonText}>x</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  );
};

/**
 * Style sheet.
 */
const styles = StyleSheet.create({
  popupContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  popup: {
    width: "86%",
    height: "9%",
    backgroundColor: "#E6E7E8",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    position: "relative",
    marginBottom: "7%",
  },
  callText: {
    fontSize: 20,
    color: "#000",
    //fontFamily: 'Inter-Medium',
    fontWeight: "400",
    letterSpacing: 0.5,
  },
  closeButton: {
    position: "absolute",
    top: "2%",
    right: "3%",
  },
  closeButtonText: {
    fontSize: 24,
    color: "#000",
  },
});

export default CallPopup;
