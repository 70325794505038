import {
  Pressable,
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  FlatList,
  Dimensions,
  Platform,
  Alert,
} from "react-native";
import React, { useState, useEffect } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from "@react-navigation/native";
import FoodAndDrinks from "../navigation/screens/assessment/FoodAndDrinks";
import SocialSupport from "../navigation/screens/assessment/SocialSupport";
import QualityOfLife from "../navigation/screens/assessment/QualityOfLife";
import FoodAccess from "../navigation/screens/assessment/FoodAccess";
import { BsArrowRightCircle } from "react-icons/bs";
import { BsArrowLeftCircle } from "react-icons/bs";
import colors from "../styles/colors";
import {BASE_URL} from "../config";

const Assessments = () => {
  const navigation = useNavigation();
  const [quizData, setQuizData] = useState(null);
  const [currentAssessmentIndex, setCurrentAssessmentIndex] = useState(0);
  const [user_id, setUserid] = useState('');

  useEffect(() => {
    console.log("From assesment");
    if(user_id.length === 0)
    { 
      getUserID();
    }
  }, []);
  const getUserID = async () => { // TODO: TEST for vulnerability
    const value = await AsyncStorage.getItem('user_id');
    if (value !== null) {
      console.log(`${BASE_URL}/surveys/check-user-survey?user_id=` + value );
      fetch(
        `${BASE_URL}/surveys/check-user-survey?user_id=` + value 
      )
        .then((response) => response.json())
        .then((data) => setQuizData(data.data))
        .catch((error) => console.error(error));
        setUserid(value);
    }
  };

  const assessments = [
    {
      id: "1",
      image: require("../assets/images/assessment/foodanddrinks1.png"),
      name: "Food and Drinks",
      screen: "FoodAndDrinks",
      disabled: false,
      label : "FOOD_AND_DRINKS",
    },
    {
      id: "2",
      image: require("../assets/images/assessment/foodaccess.png"),
      name: "Food Access",
      screen: "FoodAccess",
      disabled: false,
      label : "FOOD_ACCESS",
    },
    {
      id: "3",
      image: require("../assets/images/assessment/socialsupport1.png"),
      name: "Social Support",
      screen: "SocialSupport",
      disabled: false,
      label : "SOCIAL_SUPPORT",
    },
    {
      id: "4",
      image: require("../assets/images/assessment/wellbeing2.png"),
      name: "Quality of Life and Well-being",
      screen: "QualityOfLife",
      disabled: false,
      label : "BRFSS_MENTAL",
    },
  ];

  const goToNextAssessment = () => {
    if (currentAssessmentIndex < assessments.length - 2) {
      setCurrentAssessmentIndex(currentAssessmentIndex + 1);
    }
  };

  const goToPreviousAssessment = () => {
    if (currentAssessmentIndex >= 1) {
      setCurrentAssessmentIndex(currentAssessmentIndex - 1);
    }
  };

  const displayedAssessments = assessments.slice(
    currentAssessmentIndex,
    currentAssessmentIndex + 2
  );
  const checkstatus = (screen,label,name) =>{
    
   
    if(quizData[label])
    {
      showAlert("Survey Status","You completed survey for " +name );
    }else{

        navigation.navigate(screen)
    }

  };
  const showAlert = (title, message) => {
    console.log(Platform.OS)
    if (Platform.OS === 'web')
    {
      alert(message);
    }
    else
    {
    Alert.alert(title, message,[{ text: 'OK' }], { cancelable: false });
    }
  };
  const styles = StyleSheet.create({
    assessmentStyle: {
      marginVertical: 10,
      marginHorizontal: 7,
      backgroundColor: "white",
      padding: 20,
      borderRadius: 15,
      borderWidth: 1.5,
      borderColor: colors.borderColor,
      alignSelf: "center",
      elevation: 5, // This adds the shadow to the component (Android)
      shadowColor: "black", // Shadow color (iOS)
      shadowOffset: { width: 0, height: 2 }, // Shadow offset (iOS)
      shadowOpacity: 0.3, // Shadow opacity (iOS)
      shadowRadius: 4, // Shadow blur radius (iOS)
    },
    image: {
      width: 800,
      height: 520,
      alignItems: "center",
      justifyContent: "space-evenly",
    },

    text: {
      textAlign: "center",
      marginTop: 10,
      fontWeight: "bold",
      fontSize: 18,
    },

    webContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      paddingHorizontal: 20,
      marginVertical: 10,
      alignItems: "center", // Center the content horizontally
      justifyContent: "center", // Center the content vertically
    },
    webAssessmentStyle: {
      width: "40%",
      marginVertical: 10,
      backgroundColor: "white",
      borderRadius: 15,
      borderWidth: 2,
      padding: 15,
      borderColor: colors.borderColor,
      elevation: 5,
      shadowColor: colors.borderColor,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.3,
      shadowRadius: 4,
      alignItems: "center", // Center the content horizontally
      justifyContent: "center", // Center the content vertically
      marginHorizontal: 15,
    },
    webImage: {
      width: "80%",
      height: 320,
      borderRadius: 15,
      padding: 10,
    },
    webText: {
      textAlign: "center",
      marginTop: 10,
      fontWeight: "bold",
      fontSize: 18,
      color: colors.primaryColor,
    },
    arrow: {
      color: colors.secondaryColor,
      fontSize: 35,
      fontWeight: "bold",
    },
  });

  const isWeb = Platform.OS === "web"; // Check if the platform is web
  return (
    <View>
      {isWeb ? (
        <View>
          <View style={styles.webContainer}>
            <BsArrowLeftCircle
              style={styles.arrow}
              onClick={goToPreviousAssessment}
              disabled={currentAssessmentIndex === 0}
            />

            {displayedAssessments.map((assessment, index) => (
              <Pressable
                style={styles.webAssessmentStyle}
                key={index}
                //onPress={() => navigation.navigate(assessment.screen)}
                onPress={() => checkstatus(assessment.screen,assessment.label,assessment.name)}
              >
                <Image source={assessment.image} style={styles.webImage} />
                <Text style={styles.webText}>{assessment.name}</Text>
              </Pressable>
            ))}
            <BsArrowRightCircle
              style={styles.arrow}
              onClick={goToNextAssessment}
              disabled={currentAssessmentIndex >= assessments.length - 2}
            />
          </View>
        </View>
      ) : (
        <View style={{ padding: 10 }}>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {assessments.map((assessment, index) => (
              <Pressable
                style={styles.assessmentStyle}
                key={index}
                //onPress={() => navigation.navigate(assessment.screen)}
                onPress={() => checkstatus(assessment.screen,assessment.label,assessment.name)}
              >
                <Image source={assessment.image} style={styles.image} />
                <Text style={styles.text}>{assessment.name}</Text>
              </Pressable>
            ))}
          </ScrollView>
        </View>
      )}
    </View>
  );
};

export default Assessments;
