import { Text, View, TouchableOpacity, Image } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import colors from "../styles/colors";
import userData from "../components/UserContext";
import { useAuth } from "../components/AuthContext";
import { MaterialIcons } from '@expo/vector-icons';


export default function Profile() {
  const navigation = useNavigation();
  const user = useContext(userData);
  const { state, signOut } = useAuth();

  const [selectedTab, setSelectedTab] = useState('Account Info');
  const [infoSection, setInfoSection] = useState(null);

  useEffect(() => {
    updateInfoSection(selectedTab);
  }, [selectedTab]);

  const updateInfoSection = (tabName) => {
    
    switch(tabName) {
      case 'Account Info':
        setInfoSection(
          <View className="mt-4 mx-6 ">
          <Text className="text-4xl font-bold">Account Info</Text>
          {/* profile picture */}
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image className="w-24 h-24 rounded-full"
              source={require("../assets/images/Boko-arch-photo.png")}/>
          </TouchableOpacity>

          {/* basic info  */}
          <View className="mt-4">
            <Text className="text-2xl font-bold">Basic Info</Text>
          </View>

          {/* name  */}
          <View className="mt-3">
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Text className="text-base font-medium">Name</Text>
              <Text className="text-base font-medium text-gray-500">{state.user.full_name}</Text>
            </TouchableOpacity>
          </View>

          {/* email */}
          <View className="mt-3">
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Text className="text-base font-medium">Email</Text>
              <Text className="text-base font-medium text-gray-500">{state.user.user_id}@domain.com</Text>
            </TouchableOpacity>
          </View>

        </View>
        );
        break;
      case 'Meals':
        setInfoSection(
          <View>
          </View>
        );
        break;
      case 'Help':
        setInfoSection(
          <View>
          </View>
        );
        break;
      default:
        setInfoSection(
          <View>
          </View>
        );
        break;
    }
  }

  return (
    <SafeAreaView className="flex-1">
      <View className="justify-between" style={{backgroundColor: colors.primaryColorVariant}}>
        <Text className="text-2xl font-semibold mx-4">Account</Text>
      </View>

      {/* profile row nav */}
      <View className="flex-row" style={{backgroundColor: colors.primaryColorVariant}}>
        {['Account Info', 'Meals', 'Help'].map((tabName) => {
          var tabColor = (tabName === selectedTab) ? colors.primaryColor : colors.primaryColorVariant;
          var textColor = (tabName === selectedTab) ? ' text-gray-50' : '';
          return (
            <View className="p-4" style={{backgroundColor: tabColor}} key={tabName}>
              <TouchableOpacity
                onPress={() => setSelectedTab(tabName)}
              >
                <Text className={"text-sm font-medium " + textColor}>
                  {tabName}
                </Text>
              </TouchableOpacity>
            </View>
          );
        })}
        <View className="p-4" style={{backgroundColor: "black", marginLeft: "auto"}}>
          <TouchableOpacity onPress={() => signOut()}>
            <View style={{ flexDirection: 'row' }}>
              <MaterialIcons name="logout" size={20} color="white" />
              <Text className={"text-sm font-medium text-white"}>
                Logout
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>

      {/* info box */}
      {infoSection || <Text>Select tab to view info</Text>}
    </SafeAreaView>
  );
}
