import {
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Text,
  SafeAreaView,
  ScrollView,
  Platform,
  Modal,
} from "react-native";
import React, { useState, useLayoutEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import { BASE_URL } from "../../../config.js";
import LoginHeader from "../../../shared/LoginHeader";
import colors from "../../../styles/colors.js";
import moment from "moment";

TouchableOpacity.defaultProps = { activeOpacity: 0.8 };
const VolunteerRegister = () => {
  const nav = useNavigation();

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [user_group, setUser_group] = useState("volunteer");
  const isWeb = () => Platform.OS === "web";
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [date_of_birth, setDob] = useState("");
  const [dobError, setDobError] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [hasRegistered, setHasRegistered] = useState(false);

  const volunteerRegisterUser = async (
    first_name,
    last_name,
    email,
    password,
    phone
  ) => {
    try {
      const registerAPI = await axios.post(`${BASE_URL}/auth/signup`, {
        first_name,
        last_name,
        email,
        password,
        phone,
        user_group,
        date_of_birth,
      });
      if (registerAPI.status === 200) {
        console.log("Registration successful");
        setDialogText("Registration successful!!");
        setShowDialog(true);
        setHasRegistered(true);
        //nav.navigate("LoginView");
      } else {
        // showAlert("Error:", "Unable to register at this time");
        console.log("Registration failed");
      }
    } catch (error) {
      console.log(error.response.data.message);
      setDialogText(error.response.data.message);
      setShowDialog(true);
      console.error("Error", error);
    }
  };

  const handleOkButtonClick = () => {
    setShowDialog(false);
    if (hasRegistered) {
      nav.navigate("LoginView");
    }
  };

  const handleRegister = () => {
    let hasErrors = false;

    setFirstNameError("");
    setLastNameError("");
    setPhoneError("");
    setEmailError("");
    setPasswordError("");
    setDobError("");

    if (first_name.length < 1) {
      setFirstNameError("Please enter first name");
      hasErrors = true;
    }
    if (last_name.length < 1) {
      setLastNameError("Please enter last name");
      hasErrors = true;
    }
    if (!validateEmail(email)) {
      setEmailError("Please enter valid email address");
      hasErrors = true;
    }
    if (password.length < 6) {
      setPasswordError("Password length should be greater than 6 characters");
      hasErrors = true;
    }
    if (!validateDate(date_of_birth)) {
      console.log(date_of_birth);
      console.log(validateDate(date_of_birth));

      setDobError("Please enter a valid date in the format YYYY-MM-DD");
      hasErrors = true;
    }
    if (!validatePhoneNumber(phone)) {
      setPhoneError("Please enter valid phone number");
      hasErrors = true;
    }
    if (password.length > 0 && confirmPassword.length > 0) {
      if (password !== confirmPassword) {
        setPasswordError("Passwords do no match");
        hasErrors = true;
      }
    }

    if (!hasErrors) {
      volunteerRegisterUser(
        first_name,
        last_name,
        email,
        password,
        phone,
        user_group,
        date_of_birth
      );
    }
  };

  const validateDate = (date) => {
    const datePattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

    if (!datePattern.test(date)) {
      return false; // Invalid date format
    }

    // Parse the date using moment.js for additional validation
    const parsedDate = moment(date, "YYYY-MM-DD", true);

    // Check if the parsed date is valid
    if (!parsedDate.isValid()) {
      return false; // Invalid date
    }
    return true; // Valid date
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phone) => {
    return !isNaN(phone) && phone.length >= 10;
  };
  /* const showAlert = (title, message) => {
    console.log(Platform.OS);
    if (Platform.OS === "web") {
      alert(title, message);
    } else {
      Alert.alert(title, message, [{ text: "OK" }], { cancelable: false });
    }
  };*/
  const renderLoginHeader = isWeb() ? (
    <LoginHeader
      style={{
        width: "100%",
      }}
    />
  ) : null;

  return (
    <SafeAreaView
      style={{
        backgroundColor: "white",
      }}
    >
      {renderLoginHeader}
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          marginTop: 10,
        }}
      >
        {/* <View style={styles.screenContainer}> */}
        <View style={styles.viewStyle}>
          <Text style={styles.titleText}>Create account on NUEVA</Text>
        </View>

        <View style={[isWeb() && styles.card]}>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>First name</Text>
            <TextInput
              placeholder="Enter your first name"
              value={first_name}
              onChangeText={(text) => setFirstName(text)}
              style={styles.input}
            />
            {firstNameError ? (
              <Text style={{ color: "red" }}>{firstNameError}</Text>
            ) : null}
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Last name</Text>
            <TextInput
              placeholder="Enter your last name"
              value={last_name}
              onChangeText={(text) => setLastName(text)}
              style={styles.input}
            />
            {lastNameError ? (
              <Text style={{ color: "red" }}>{lastNameError}</Text>
            ) : null}
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Date of birth(YYYY-MM-DD)</Text>
            <TextInput
              placeholder="Enter your date of birth(YYYY-MM-DD)"
              value={date_of_birth}
              onChangeText={(text) => setDob(text)}
              style={styles.input}
              maxLength={10}
            />{" "}
            {dobError ? <Text style={{ color: "red" }}>{dobError}</Text> : null}
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Email address</Text>
            <TextInput
              placeholder="Enter your email address"
              value={email}
              onChangeText={(text) => setEmail(text)}
              style={styles.input}
              keyboardType="email-address"
            />{" "}
            {emailError ? (
              <Text style={{ color: "red" }}>{emailError}</Text>
            ) : null}
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Password</Text>
            <TextInput
              placeholder="Enter your password"
              value={password}
              onChangeText={(text) => setPassword(text)}
              secureTextEntry={true}
              style={styles.input}
            />{" "}
            {passwordError ? (
              <Text style={{ color: "red" }}>{passwordError}</Text>
            ) : null}
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Confirm password</Text>
            <TextInput
              placeholder="Retype password"
              value={confirmPassword}
              onChangeText={(text) => setConfirmPassword(text)}
              secureTextEntry={true}
              style={styles.input}
            />{" "}
            {passwordError ? (
              <Text style={{ color: "red" }}>{passwordError}</Text>
            ) : null}
          </View>
          <View style={styles.viewStyle}>
            <Text style={styles.text}>Phone Number</Text>
            <TextInput
              placeholder="Enter your phone number"
              value={phone}
              onChangeText={(text) => setPhone(text)}
              style={styles.input}
            />{" "}
            {phoneError ? (
              <Text style={{ color: "red" }}>{phoneError}</Text>
            ) : null}
          </View>
          <View style={styles.buttonAlign}>
            <AppButton
              title="Create account"
              backgroundColor={colors.primaryColorVariant}
              onPress={handleRegister}
            />
          </View>
        </View>
      </View>
      <Modal visible={showDialog} transparent>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text>{dialogText}</Text>
            <TouchableOpacity
              onPress={() => handleOkButtonClick()}
              style={styles.okButton}
            >
              <Text style={styles.okButtonText}>Ok</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};
const AppButton = ({ onPress, title, backgroundColor }) => (
  <TouchableOpacity
    onPress={onPress}
    style={[styles.appButtonContainer, backgroundColor && { backgroundColor }]}
  >
    <Text style={styles.appButtonText}>{title}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 80,
  },
  appButton: {
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  appButtonContainer: {
    elevation: 8,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "space-between",
    height: 50,
    width: 200,
    marginTop: 10,
    borderRadius: 25,
    width: "90%",
  },
  appButtonText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase",
  },
  buttonAlign: {
    flexDirection: "column",
    borderBottomColor: "#ccc",
    paddingBottom: 8,
    marginBottom: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 16,
    // fontWeight: 400,
  },
  input: {
    backgroundColor: "#fff",
    padding: 10,
    width: "100%",
    marginTop: 15,
    height: 48,
    borderColor: "#000000",
    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  viewStyle: {
    flexDirection: "column",
    borderBottomColor: "#ccc",
    paddingBottom: 8,
    marginBottom: 25,
  },
  titleText: {
    fontSize: 30,
    fontWeight: "bold",
    color: "black",
    alignContent: "center",
    marginTop: 0,
    color: colors.primaryColor,
  },
  card: {
    width: "50%",
    backgroundColor: "white",
    borderRadius: 8,
    padding: 16,
    paddingTop: 20,
    marginHorizontal: 150,
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background color
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    borderWidth: 1, // Add border width
    borderColor: "#C0C0C0", // Add border color
  },
  okButton: {
    backgroundColor: colors.primaryColorVariant, // Customize the button background color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 15,
  },
  okButtonText: {
    color: "white", // Customize the button text color
    fontSize: 16,
  },
});
export default VolunteerRegister;
