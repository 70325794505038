import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

// Creates a Context that allows different React components to
// share data without needing to pass props to one another using HomeStack.js
const DataContext = React.createContext();

const DataProvider = ({ children }) => {
    
    const [dataState, dispatch] = useReducer(
        (prevDataState, action) => {
            switch (action.type) {
                case 'SET_CLIENTS_DATA':
                    return { ...prevDataState, clientsData: action.payload.clientsData };
                case 'UPDATE_DELIVERY_STATUS':
                    return {
                        ...prevDataState,
                        clientsData: prevDataState.clientsData.map((client) => 
                            (client.delivery_id === action.payload.delivery_id)
                            ? { ...client, delivered: action.payload.delivered, delivery: action.payload.delivered ? "Delivered" : "Not Delivered" }
                            : client
                        ),
                    };
                default:
                    return { ...prevDataState };
            }
        },
        {
            clientsData: [],
        }
    );

    const updateDeliveryStatus = (delivery_id, delivered) => {
        dispatch({ type: 'UPDATE_DELIVERY_STATUS', payload: {delivery_id, delivered} });
    }

    const setClientsData = (clientsData) => {
        dispatch({ type: 'SET_CLIENTS_DATA', payload: { clientsData } });
    }

    const dataContextValue = {
        updateDeliveryStatus,
        setClientsData,
        dataState,
    }

    return (
        <DataContext.Provider value={dataContextValue}>{children}</DataContext.Provider>
    );
}; 

const useData = () => useContext(DataContext);

export { DataProvider, useData };
