import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  Button,
  StyleSheet,
  ScrollView,
  Modal,
  Platform, // Import the Platform module
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Alert } from "react-native";
import WeekSelection from "./WeekSelection";
import CustomHeader from "../../../shared/CustomHeader";
import colors from "../../../styles/colors";
import BottomNavigationBar from "../../../shared/BottomNavigationBar";

const CustomCheckBox = (props) => {
  const { value, onValueChange } = props;
  return (
    <TouchableOpacity onPress={onValueChange}>
      <View style={styles.checkboxContainer}>
        <View
          style={[
            styles.checkbox,
            {
              backgroundColor: value ? "#6B9626" : "white",
              borderColor: value ? "#6B9626" : "#ccc",
            },
          ]}
        >
          {value && <Text style={styles.checkmark}>&#10003;</Text>}
        </View>
        <Text style={styles.checkboxLabel}>{props.label}</Text>
      </View>
    </TouchableOpacity>
  );
};

const MenuChoiceForm = () => {
  const navigation = useNavigation();
  const [makeOwnChoices, setMakeOwnChoices] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, []);

  const [restrictions, setRestrictions] = useState({
    pork: false,
    beef: false,
    poultry: false,
    fish: false,
  });

  const handleRestrictionChange = (restriction) => {
    if (makeOwnChoices) {
      setMakeOwnChoices(false);
    }
    setRestrictions((prevRestrictions) => ({
      ...prevRestrictions,
      [restriction]: !prevRestrictions[restriction],
    }));
  };

  const handleMakeOwnChoiceChange = () => {
    if (Object.values(restrictions).some((value) => value)) {
      setRestrictions({
        pork: false,
        beef: false,
        poultry: false,
        fish: false,
      });
    }
    setMakeOwnChoices(!makeOwnChoices);
  };

  const handleOkButtonClick = () => {
    setShowDialog(false);
  };

  const handleFormSubmit = () => {
    // Check if at least one restriction is selected
    const isAtLeastOneSelected = Object.values(restrictions).some(
      (value) => value
    );

    if (!isAtLeastOneSelected && !makeOwnChoices) {
      // Display an alert if no checkbox is selected
      setDialogText("Please select one of the boxes");
      setShowDialog(true);
      return; // Prevent form submission
    }

    // If at least one checkbox is selected, proceed with form submission logic
    // For example, you can navigate to the next screen or perform other actions.
    if (makeOwnChoices) {
      navigation.navigate("WeekSelection");
    } else if (isAtLeastOneSelected) {
      setDialogText("Your preferences have been submitted!");
      setShowDialog(true);
    }
  };
  const isWeb = () => Platform.OS === "web";
  //Render custom header only on web UI
  const renderCustomHeader = isWeb() ? <CustomHeader /> : null;
  const renderBottomNaigation = isWeb() ? null : <BottomNavigationBar />;
  return (
    <View style={styles.maincontainer}>
      {renderCustomHeader}
      <View style={styles.container}>
        <View style={[isWeb() && styles.card]}>
          <Text style={[styles.title, !isWeb() && styles.margin]}>
            Fall Winter Menu Choice Form
          </Text>
          <Text style={styles.subtitle}>October 2022 - March 2023</Text>
          <Text style={[styles.description, !isWeb() && styles.margin]}>
            We can select your meal choices for you if it meets one of the
            restrictions below. Or you can make your own choices if you have no
            restrictions.
          </Text>

          <View
            style={[styles.restrictionContainer, !isWeb() && styles.margin]}
          >
            <Text style={styles.label}>Please check one of the boxes:</Text>
            <CustomCheckBox
              label="I don't want pork"
              value={restrictions.pork}
              onValueChange={() => handleRestrictionChange("pork")}
            />
            <CustomCheckBox
              label="I don't want beef"
              value={restrictions.beef}
              onValueChange={() => handleRestrictionChange("beef")}
            />
            <CustomCheckBox
              label="I don't want poultry"
              value={restrictions.poultry}
              onValueChange={() => handleRestrictionChange("poultry")}
            />
            <CustomCheckBox
              label="I don't want fish"
              value={restrictions.fish}
              onValueChange={() => handleRestrictionChange("fish")}
            />
          </View>
          <Text style={[styles.label, !isWeb() && styles.margin]}>
            If you want to choose the menu by yourself, select the option below
          </Text>
          <CustomCheckBox
            label="I will make my own choices below"
            value={makeOwnChoices}
            onValueChange={handleMakeOwnChoiceChange}
          />

          <TouchableOpacity
            style={[styles.submitButton, !isWeb() && styles.margin]}
            onPress={handleFormSubmit}
          >
            <Text style={styles.submitButtonText}>Submit</Text>
          </TouchableOpacity>
        </View>

        <Modal visible={showDialog} transparent>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text>{dialogText}</Text>
              <TouchableOpacity
                onPress={() => handleOkButtonClick()}
                style={styles.okButton}
              >
                <Text style={styles.okButtonText}>Ok</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
      {renderBottomNaigation}
    </View>
  );
};

const styles = StyleSheet.create({
  maincontainer: {
    flex: 1,
    backgroundColor: "white",
  },

  container: {
    flex: 1,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  card: {
    backgroundColor: "white",
    borderRadius: 8,
    elevation: 5, // Android shadow
    shadowColor: "black", // iOS shadow
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: "center",
  },
  description: {
    marginBottom: 20,
    textAlign: "center",
  },
  restrictionContainer: {
    marginBottom: 20,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  checkboxRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkboxLabel: {
    marginLeft: 10,
  },
  makeOwnChoicesContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  makeOwnChoicesLabel: {
    marginLeft: 10,
  },
  ownChoicesContainer: {
    marginBottom: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
  submitButton: {
    backgroundColor: colors.primaryColorVariant,
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  submitButtonText: {
    color: "white",
    fontWeight: "bold",
  },

  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background color
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    borderWidth: 1, // Add border width
    borderColor: "#C0C0C0", // Add border color
  },
  okButton: {
    backgroundColor: "#0096FF", // Customize the button background color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 15,
  },
  okButtonText: {
    color: "white", // Customize the button text color
    fontSize: 16,
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 2,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  checkmark: {
    color: "white",
    fontSize: 14,
  },
  margin: {
    marginTop: 10,
  },
});

export default MenuChoiceForm;
