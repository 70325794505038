import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { FontSize, Color, FontFamily, Border } from "../../styles/GlobalStyles";

const ModalContent = ({
  handleCloseButtonPress,
  getOverlayContent,
  styles,
}) => {
  // Extracting overlayContent using the provided function
  const overlayContent = getOverlayContent();

  // Splitting overlayContent into title and content lines
  const contentLines = overlayContent.split("\n");
  const title = contentLines[0];
  const content = contentLines.slice(1);

  return (
    <View style={styles.overlay}>
      <View style={styles.box}>
        {/* <TouchableOpacity style={styles.closeButton} onPress={handleCloseButtonPress}>
          <Text style={styles.closeButtonText}>X</Text>
        </TouchableOpacity> */}
        <Text style={styles.modalTitle}>{title}</Text>
        <View>
          {/* Mapping through content lines and rendering each line */}
          {content.map((line, index) => {
            // Checking if the line starts with a bullet point indicator
            if (line.startsWith("•")) {
              return (
                <View style={modalStyles.bulletPoint} key={index}>
                  <Text>•</Text>
                  <Text style={styles.modalText}>{line.slice(1)}</Text>
                </View>
              );
            } else {
              // Rendering regular text lines
              return (
                <Text style={styles.modalTitle} key={index}>
                  {line}
                </Text>
              );
            }
          })}
        </View>
      </View>
    </View>
  );
};

export default ModalContent;

const modalStyles = StyleSheet.create({
  bulletPoint: {
    flexDirection: "row",
    alignItems: "center",
  },
});
