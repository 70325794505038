import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { Feather } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import colors from "../styles/colors";
import { useAuth } from "../components/AuthContext";

const CustomHeader = () => {
  const navigation = useNavigation();
  const { state, signOut } = useAuth();
  return (
    <View style={{ backgroundColor: colors.primaryColorVariant }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          padding: 12,
          marginTop: 30,
          backgroundColor: colors.primaryColor,
        }}
      >
        <Image
          source={require("../assets/logo.png")}
          style={{ height: 55, width: 50, marginHorizontal: 15 }}
        />
        <TouchableOpacity
          style={{ flexDirection: "row", marginHorizontal: 12, marginTop: 7 }}
          onPress={() => navigation.navigate("HomeScreen")}
        >
          <Feather name="home" size={20} color="white" />
          <Text style={{ color: "white", fontSize: 16, marginHorizontal: 5 }}>
            Home
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flexDirection: "row", marginHorizontal: 10, marginTop: 7 }}
          onPress={() => navigation.navigate("Profile")}
        >
          <Feather name="user" size={20} color="white" />
          <Text style={{ color: "white", fontSize: 16, marginHorizontal: 5 }}>
            Profile
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            flexDirection: "row",
            marginHorizontal: 10,
            marginTop: 7,
            marginLeft: "auto",
          }}
          onPress={() => signOut()}
        >
          <MaterialIcons name="logout" size={20} color="white" />
          <Text style={{ color: "white", fontSize: 16, marginHorizontal: 5 }}>
            Logout
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default CustomHeader;
