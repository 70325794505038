import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NativeWindStyleSheet } from "nativewind";
import { useAuth } from "../components/AuthContext";
import * as Linking from 'expo-linking'
import { View, Text, TouchableOpacity } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import VolunteerChoice from "./screens/login/VolunteerChoice";
import LoginButtons from "./screens/login/LoginButtons";
import VolunteerButtons from "./screens/login/VolunteerButtons";
import LoginView from "./screens/login/LoginView";
import SignUpView from "./screens/login/SignUpView";
import KnockOut from "./screens/login/KnockOut";
import VolunteerRegister from "./screens/login/VolunteerRegister";
import Advocate from "./screens/login/Advocate"
import HomeScreen from "./HomeScreen";
import HomeStack from "./screens/HomeStack";
import FoodAndDrinks from "./screens/assessment/FoodAndDrinks";
import SocialSupport from "./screens/assessment/SocialSupport";
import QualityOfLife from "./screens/assessment/QualityOfLife";
import FoodAccess from "./screens/assessment/FoodAccess";
import MealProviderOption from "./screens/meals/MealProviderOption";
import MenuChoiceForm from "./screens/meals/MenuChoiceForm";
import MealCalendar from "./screens/meals/MealCalendar";
import WeekSelection from "./screens/meals/WeekSelection";
import MealSelection from "./screens/meals/MealSelection";
import FavoritesScreen from "./FavoritesScreen";
import Profile from "./Profile";
import CalendarWeekSelection from "./screens/meals/CalendarWeekSelection";
import { BASE_URL } from "../config";
import { configureInternal } from "@testing-library/react-native/build/config";

NativeWindStyleSheet.setOutput({
    default: "native",
  });
  
  const BaseStack = createNativeStackNavigator();
  
  const linking = {
    prefixes: [Linking.createURL('/')],
    config: {
      screens: {
        VolunteerChoice: {
          path: 'VolunteerChoice',
          screens: {
            LoginButtons: {
              path: 'LoginButtons',
              screens: {
                LoginView: 'LoginView',
                SignUpView: 'SignUpView',
                KnockOut: 'KnockOut',
                Advocate: 'Advocate',
                VolunteerRegister: 'VolunteerRegister',
              },
            },
            VolunteerButtons: {
              path: 'VolunteerButtons',
              screens: {
                HomeScreen: 'HomeScreen',
                HomeStack: {
                  path: 'HomeStack',
                  screens: {
                    FoodAndDrinks: 'FoodAndDrinks',
                    SocialSupport: 'SocialSupport',
                    QualityOfLife: 'QualityOfLife',
                    FoodAccess: 'FoodAccess',
                    MealProviderOption: 'MealProviderOption',
                    MenuChoiceForm: 'MenuChoiceForm',
                    MealCalendar: 'MealCalendar',
                    WeekSelection: 'WeekSelection',
                    MealSelection: 'MealSelection',
                    FavoritesScreen: 'FavoritesScreen',
                    Profile: 'Profile',
                    CalendarWeekSelection: 'CalendarWeekSelection',
                  },
                },
              },
            },
          },
        },
      }
    }
  }

export default function AppNavigator() {
  const authContext = useAuth();
    
  return (
    <NavigationContainer linking={linking} >
     <BaseStack.Navigator screenOptions={{ headerTitle: "", headerShown: false }}>
      {
        authContext.state.user == null ? (  // TODO: change back to userToken once token based login is re-implemented
            <>
              <BaseStack.Screen name="VolunteerChoice" component={VolunteerChoice} />
              <BaseStack.Screen name="LoginButtons" component={LoginButtons} />
              <BaseStack.Screen name="LoginView" component={LoginView} />
              <BaseStack.Screen name="VolunteerButtons" component={VolunteerButtons}/>
              <BaseStack.Screen name="SignUpView" component={SignUpView} />
              <BaseStack.Screen name="VolunteerRegister" component={VolunteerRegister} />
              <BaseStack.Screen name="KnockOut" component={KnockOut} />
              <BaseStack.Screen name="Advocate" component={Advocate}/>
          </>
        ) : (
            authContext.state.user.user_group == "client" ? (
                <>
                    {/* for Client */}
                    <BaseStack.Screen name="HomeScreen" component={HomeScreen} />
                    <BaseStack.Screen name="FoodAndDrinks" component={FoodAndDrinks} />
                    <BaseStack.Screen name="SocialSupport" component={SocialSupport} />
                    <BaseStack.Screen name="QualityOfLife" component={QualityOfLife} />
                    <BaseStack.Screen name="FoodAccess" component={FoodAccess} />
                    <BaseStack.Screen
                      name="MealProviderOption"
                      component={MealProviderOption}
                    />
                    <BaseStack.Screen name="MenuChoiceForm" component={MenuChoiceForm} />
                    <BaseStack.Screen name="MealCalendar" component={MealCalendar} />
                    <BaseStack.Screen name="WeekSelection" component={WeekSelection} />
                    <BaseStack.Screen name="MealSelection" component={MealSelection} />
                    <BaseStack.Screen name="FavoritesScreen" component={FavoritesScreen} />
                    <BaseStack.Screen
                      name="CalendarWeekSelection"
                      component={CalendarWeekSelection}
                    />
                    <BaseStack.Screen name="Profile" component={Profile} />
                </>
            ) : (
                <>
                    {/* for Volunteer */}
                    <BaseStack.Screen name="HomeStack" component={HomeStack} /> 
                    <BaseStack.Screen name="Profile" component={Profile} />
                </>

            )
        )
      }
    </BaseStack.Navigator>
  </NavigationContainer>
  );
}
