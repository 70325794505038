import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Modal,
  Button, // Import Button component
  Platform,
} from "react-native";
import { useRoute, useNavigation } from "@react-navigation/native";
import axios from "axios";
import mealData from "./mealData";
import CustomHeader from "../../../shared/CustomHeader";
import colors from "../../../styles/colors";
import BottomNavigationBar from "../../../shared/BottomNavigationBar";
import { SafeAreaView } from "react-native-safe-area-context";
import { BASE_URL } from "../../../config";

const MealSelection = () => {
  const [data, setData] = useState(mealData.data);
  const [selectedMeals, setSelectedMeals] = useState({});
  const [selectAllOptionA, setSelectAllOptionA] = useState(false); // State to track select all for Option A
  const [selectAllOptionB, setSelectAllOptionB] = useState(false); // State to track select all for Option B
  const [showDialog, setShowDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const route = useRoute();
  const navigation = useNavigation();
  const selectedWeek = route.params.selectedWeek || "WEEK1"; // Default to "WEEK1" if no parameter is provided
  const [selectedMealInfo, setSelectedMealInfo] = useState({
    name: "",
    description: "",
  });
  const [showMealModal, setShowMealModal] = useState(false);
  useEffect(() => {
    // Make a GET API call when the component mounts
    const filteredData = data.filter((item) => item.week === selectedWeek);
    setData(filteredData);
  }, [selectedWeek]);

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, []);

  const handleMealCardClick = (day, choice) => {
    const mealKey = `${day}_${choice}`;
    setSelectedMeals((prevSelected) => {
      // Create a new object for selected meals
      const newSelectedMeals = { ...prevSelected };

      // Toggle the selected state for the clicked meal
      newSelectedMeals[mealKey] = !newSelectedMeals[mealKey];

      // If Option A is selected, deselect Option B and vice versa
      if (choice === "CHOICE_A") {
        const oppositeMealKey = `${day}_CHOICE_B`;
        newSelectedMeals[oppositeMealKey] = false;
      } else if (choice === "CHOICE_B") {
        const oppositeMealKey = `${day}_CHOICE_A`;
        newSelectedMeals[oppositeMealKey] = false;
      }

      return newSelectedMeals;
    });
  };

  const isMealSelected = (day, choice) => {
    const mealKey = `${day}_${choice}`;
    return selectedMeals[mealKey];
  };

  // Function to handle select all for Option A
  const handleSelectAllOptionA = () => {
    // Toggle the select all state for Option A
    setSelectAllOptionA(!selectAllOptionA);

    // If selecting all for Option A, deselect all for Option B
    if (!selectAllOptionA) {
      setSelectAllOptionB(false);
    }

    // Update the selected meals state accordingly
    setSelectedMeals((prevSelected) => {
      const newSelectedMeals = { ...prevSelected };

      data.forEach((item) => {
        if (item.meal_category === "CHOICE_A") {
          const mealKey = `${item.day_of_the_week}_CHOICE_A`;
          newSelectedMeals[mealKey] = !selectAllOptionA;
        }
      });

      // Deselect all for Option B
      if (!selectAllOptionA) {
        data.forEach((item) => {
          if (item.meal_category === "CHOICE_B") {
            const mealKey = `${item.day_of_the_week}_CHOICE_B`;
            newSelectedMeals[mealKey] = false;
          }
        });
      }

      return newSelectedMeals;
    });
  };

  const handleOkButtonClick = () => {
    setShowDialog(false);
    // Navigate to the home screen

    navigation.navigate("MealProviderOption"); // Replace "Home" with the name of your home screen
  };

  // Function to handle select all for Option B
  const handleSelectAllOptionB = () => {
    // Toggle the select all state for Option B
    setSelectAllOptionB(!selectAllOptionB);

    // If selecting all for Option B, deselect all for Option A
    if (!selectAllOptionB) {
      setSelectAllOptionA(false);
    }

    // Update the selected meals state accordingly
    setSelectedMeals((prevSelected) => {
      const newSelectedMeals = { ...prevSelected };

      data.forEach((item) => {
        if (item.meal_category === "CHOICE_B") {
          const mealKey = `${item.day_of_the_week}_CHOICE_B`;
          newSelectedMeals[mealKey] = !selectAllOptionB;
        }
      });

      // Deselect all for Option A
      if (!selectAllOptionB) {
        data.forEach((item) => {
          if (item.meal_category === "CHOICE_A") {
            const mealKey = `${item.day_of_the_week}_CHOICE_A`;
            newSelectedMeals[mealKey] = false;
          }
        });
      }

      return newSelectedMeals;
    });
  };

  const placeOrder = async () => {
    // Prepare the request body
    const requestBody = {
      meal_order_ids: Object.entries(selectedMeals)
        .filter(([mealKey, isSelected]) => isSelected)
        .map(([mealKey]) => {
          // Extract the meal ID from the mealKey (e.g., "MONDAY_CHOICE_A")
          console.log("mealKey:", mealKey);
          const dayOfWeek = mealKey.split("_")[0];
          const mealCategory =
            mealKey.split("_")[1] + "_" + mealKey.split("_")[2];

          console.log("dayOfWeek:", dayOfWeek);
          console.log("mealCategory:", mealCategory);

          // Find the corresponding meal in the mealData JSON using the day and category
          const selectedMeal = mealData.data.find(
            (meal) =>
              meal.day_of_the_week === dayOfWeek &&
              meal.meal_category === mealCategory
          );

          console.log("selectedMeal:", selectedMeal);

          // Return the ID of the selected meal
          return selectedMeal ? selectedMeal.id : null;
        })
        .filter((mealId) => mealId !== null), // Remove null values
      user_id: 236, // Replace with the actual user ID
      meal_provider_id: 2, // Replace with the actual meal provider ID
    };

    try {
      // Make the POST request
      const response = await axios.post(
        `${BASE_URL}/provider-meal/assign-meals/`,
        requestBody
      );

      // Handle the response here
      setDialogText("Order placed successfully!!");
      setShowDialog(true);
      console.log("Order placed successfully:", response.data);

      // You can also navigate to a confirmation screen or perform other actions here
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error placing order:", error);
    }
  };

  const openMealModal = (mealName, mealDescription, day, choice) => {
    setSelectedMealInfo({
      name: mealName,
      description: mealDescription,
    });
    setShowMealModal(true);
    handleMealCardClick(day, choice);
  };

  const isWeb = () => Platform.OS === "web";
  //Render custom header only on web UI
  const renderCustomHeader = isWeb() ? <CustomHeader /> : null;
  const renderBottomNaigation = isWeb() ? null : <BottomNavigationBar />;

  return (
    <SafeAreaView style={styles.container}>
      {renderCustomHeader}
      <ScrollView>
        <View>
          <Text style={styles.headerText}>
            Meal Selection for {selectedWeek}
          </Text>
        </View>
        <View style={[isWeb() && styles.maintable]}>
          <View style={styles.tableHeader}>
            <Text style={styles.columnHeader}>
              Option A
              <TouchableOpacity
                style={styles.selectAllOption}
                onPress={handleSelectAllOptionA}
              >
                <Text style={styles.selectAllOptionText}>
                  {selectAllOptionA ? "Deselect All" : "Select All"}
                </Text>
              </TouchableOpacity>
            </Text>
            <Text style={styles.columnHeader}>
              Option B
              <TouchableOpacity
                style={styles.selectAllOption}
                onPress={handleSelectAllOptionB}
              >
                <Text style={styles.selectAllOptionText}>
                  {selectAllOptionB ? "Deselect All" : "Select All"}
                </Text>
              </TouchableOpacity>
            </Text>
          </View>
          {["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"].map(
            (day) => {
              const choiceA = data.find(
                (item) =>
                  item.day_of_the_week === day &&
                  item.meal_category === "CHOICE_A"
              );
              const choiceB = data.find(
                (item) =>
                  item.day_of_the_week === day &&
                  item.meal_category === "CHOICE_B"
              );

              return (
                <View
                  key={day}
                  style={[
                    isWeb() && styles.table,
                    !isWeb() && styles.androidrow,
                  ]}
                >
                  <Text style={styles.dayLabel}>{day}</Text>
                  <View style={styles.dayLabelLine}></View>
                  <View style={[styles.tableRow]}>
                    <TouchableOpacity
                      style={[
                        styles.columnData,
                        isMealSelected(day, "CHOICE_A")
                          ? styles.selectedMealCard
                          : styles.mealCard,
                      ]}
                      onPress={() =>
                        openMealModal(
                          choiceA?.meal_name,
                          choiceA?.meal_description,
                          day,
                          "CHOICE_A"
                        )
                      }
                    >
                      <Text
                        style={[
                          styles.mealCardText,
                          isMealSelected(day, "CHOICE_A")
                            ? styles.selectedMealCardText
                            : {},
                        ]}
                      >
                        {choiceA ? choiceA.meal_name : ""}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.columnData,
                        isMealSelected(day, "CHOICE_B")
                          ? styles.selectedMealCard
                          : styles.mealCard,
                      ]}
                      onPress={() =>
                        openMealModal(
                          choiceB?.meal_name,
                          choiceB?.meal_description,
                          day,
                          "CHOICE_B"
                        )
                      }
                    >
                      <Text
                        style={[
                          styles.mealCardText,
                          isMealSelected(day, "CHOICE_B")
                            ? styles.selectedMealCardText
                            : {},
                        ]}
                      >
                        {choiceB ? choiceB.meal_name : ""}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={[!isWeb() && styles.dayLabelLineMobile]}></View>
                </View>
              );
            }
          )}
        </View>
        <TouchableOpacity
          style={[isWeb && styles.submitButton]}
          onPress={placeOrder}
        >
          <Text style={styles.submitButtonText}>Place your order</Text>
        </TouchableOpacity>
        <Modal visible={showDialog} transparent>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text>{dialogText}</Text>
              <TouchableOpacity
                onPress={() => handleOkButtonClick()}
                style={styles.okButton}
              >
                <Text style={styles.okButtonText}>Ok</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        <Modal visible={showMealModal} transparent>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.headerText}>{selectedMealInfo.name}</Text>
              <Text>
                Served with smooth mashed potatoes and char-grilled, crispy
                asparagus, topped with a tangy golden-brown glaze for a meal
                hard to forget.
              </Text>
              <TouchableOpacity
                onPress={() => setShowMealModal(false)}
                style={styles.okButton}
              >
                <Text style={styles.okButtonText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </ScrollView>
      {renderBottomNaigation}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  card: {
    backgroundColor: "white",
    borderColor: colors.primaryColor,
    borderRadius: 8,
    elevation: 5, // Android shadow
    shadowColor: "black", // iOS shadow
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    padding: 16,
    marginBottom: 16,
  },
  headerText: {
    fontSize: 19,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
    marginTop: 5,
  },
  maintable: {
    marginTop: 8,
    backgroundColor: "white",
    borderRadius: 8,
    elevation: 5, // Android shadow
    shadowColor: "black", // iOS shadow
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    padding: 16,
    marginHorizontal: 120,
  },

  table: {
    marginTop: 8,
    backgroundColor: "white",
    borderRadius: 8,
    elevation: 5, // Android shadow
    shadowColor: "black", // iOS shadow
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    padding: 16,
    marginHorizontal: 60,
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
    borderBottomWidth: 1,
    borderColor: "#ccc",
    paddingBottom: 8,
  },
  columnHeader: {
    flex: 1,
    fontSize: 16,
    textAlign: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  columnData: {
    flex: 1,
    fontSize: 16,
    textAlign: "center",
  },
  mealCard: {
    borderColor: colors.secondaryColor,
    borderWidth: 1.7,
    borderRadius: 8,
    padding: 8,
    alignItems: "center",
    margin: 5,
  },
  mealCardText: {
    fontSize: 14,
    padding: 5,
    elevation: 2,
  },

  selectedMealCard: {
    backgroundColor: "#6B9626",
    borderWidth: 2,
    borderColor: "#c3d5a8",
    borderRadius: 8,
    padding: 8,
    alignItems: "center",
    margin: 5,
  },
  selectedMealCardText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 14,
    padding: 5,
  },
  submitButton: {
    marginTop: 20,
    backgroundColor: colors.primaryColorVariant,
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginHorizontal: 200,
    marginBottom: 16,
  },
  submitButtonText: {
    color: "white",
    fontWeight: "bold",
  },
  selectAllOption: {
    backgroundColor: colors.primaryColorVariant,
    padding: 10,
    margin: 5,
    borderRadius: 5,
    alignItems: "center",
  },
  selectAllOptionText: {
    color: "white",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background color
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    borderWidth: 1, // Add border width
    borderColor: "#C0C0C0", // Add border color
  },
  okButton: {
    backgroundColor: colors.primaryColorVariant, // Customize the button background color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 15,
  },
  okButtonText: {
    color: "white", // Customize the button text color
    fontSize: 16,
  },
  dayLabel: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 8,
  },
  dayLabelLine: {
    borderBottomWidth: 1, // Add a 1-pixel border
    borderColor: "#ccc", // Customize the border color
    marginBottom: 8, // Add some space between the line and the content below
  },
  androidrow: {
    marginBottom: 10,
  },

  dayLabelLineMobile: {
    borderBottomWidth: 1, // Add a 1-pixel border
    borderColor: "#ccc", // Customize the border color
    marginBottom: 8, // Add some space between the line and the content below
    elevation: 1.5, // Android shadow
    shadowColor: "black", // iOS shadow
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
  },
});
export default MealSelection;
