const colors = {
  primaryColor: "#003147",
  primaryColorVariant: "#00b8c4", //light blue
  secondaryColor: "#f04925", //orange
  secondaryColorVariant: "#FF9506", //light orange
  tertiaryColor: "rgb(171, 208, 55)", //green
  tertiaryColorVariant: "#c2e24c",

  textColor: "#000",
  backgroundColor: "#fefefe",
  buttonColor: "#54D2D2",
  buttonTextColor: "white",
  borderColor: "#C0C0C0",
};

export default colors;
