import {
  View,
  Text,
  SafeAreaView,
  StyleSheet,
  StatusBar,
  Platform,
  TextInput,
  Image,
  ImageBackground,
  ScrollView,
} from "react-native";
import React, { useLayoutEffect,useEffect,useState } from "react";
import { useNavigation} from "@react-navigation/native";

import AsyncStorage from '@react-native-async-storage/async-storage';
import Assessments from "../components/Assessments";
import Meals from "../components/Meals";
import { LinearGradient } from "expo-linear-gradient";
import Groceries from "../components/Groceries";
import CustomHeader from "../shared/CustomHeader";
import colors from "../styles/colors";
import BottomNavigationBar from "../shared/BottomNavigationBar";
const HomeScreen = () => {
  const navigation = useNavigation();
  const [uname, setUname] = useState('');
  
  useEffect(() => {
    
    if(uname.length === 0)
    { 
      getUser();
    }
    

  });

  const getUser = async () => {
    const value = await AsyncStorage.getItem('full_name');
    if (value !== null) {
      setUname(value);
    }
  };
  
  const styles = StyleSheet.create({
    searchBar: {
      backgroundColor: colors.backgroundColor,
      marginTop: 30,
      padding: 10,
      margin: 10,
      flexDirection: "row",
      alignContent: "center",
      justifyContent: "space-between",
      borderWidth: 0.8,
      borderColor: "#C0C0C0",
      borderRadius: 7,
    },

    image: {
      flex: 1,
      resizeMode: "cover",
    },

    username: {
      marginLeft: 20,
      marginTop: 40,
      color: "#000000",
      fontSize: 15,
    },
    container: {
      flex: 1,
      height: "100%",
      backgroundColor: "white",
    },
  });
  const isWeb = () => Platform.OS === "web";
  //Render custom header only on web UI
  const renderCustomHeader = isWeb() ? <CustomHeader /> : null;
  const renderBottomNaigation = isWeb() ? null : <BottomNavigationBar />;
  return (
    /*Search bar*/
    <SafeAreaView
      style={{
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      {renderCustomHeader}
      <ScrollView showsVerticalScrollIndicator={true}>
        <View style={styles.container}>
          <Text style={styles.username}>Hello {uname.split(' ')[0]}!</Text>

          <Assessments
            style={{
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "center",
            }}
          />
          <Meals />
          <Groceries />
        </View>
      </ScrollView>
      {renderBottomNaigation}
    </SafeAreaView>
  );
};

export default HomeScreen;
