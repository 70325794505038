
import React, { useState,useEffect } from 'react';
import { View, Platform,StyleSheet, TouchableOpacity, Text, Image, ScrollView, Alert} from "react-native";
import {  useRoute } from "@react-navigation/native";
import { Picker } from '@react-native-picker/picker';


//const Stack = createStackNavigator();

TouchableOpacity.defaultProps = { activeOpacity: 0.8 };
    const  KnockOut = ({navigation}) => {
    const route = useRoute();
    const isAdvocate = route.params?.isAdvocate || false;

    const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
    const [answers, setAnswers] = useState(Array(6).fill(null));
    const [showarray, setShowarray] = useState(Array(6).fill(false));

    //let showarray=[true,false,false,false,false,false]

    const handleDropdownChange = (value) => {
        setSelectedDropdownValue(value);
        if (value === 'Other') {
            if (isAdvocate)
            {
                navigation.navigate('Advocate');
            }else{
                navigation.navigate('SignUpView');
            }
             // Navigate to the new screen

        }
    };
    const handleHideview = (questionIndex,flag) => {
        const newshowarray = [...showarray];
        newshowarray[questionIndex] = flag;
        setShowarray(newshowarray);
    };
     useEffect(() => {
        // Update the document title using the browser API
        if (showarray[0] === false)
        {
        handleHideview(0,true);
        }
      });
    
    const handleAnswerSelection = (questionIndex, answerIndex) => {
        const newAnswers = [...answers];
        newAnswers[questionIndex] = answerIndex;
        setAnswers(newAnswers);
     
        if ( (questionIndex < 4 ) && answerIndex === 1)
        {
            handleHideview(questionIndex+1,true);
        }else if(questionIndex === 4 )
        {
            handleHideview(questionIndex+1,true);
        } else if ((questionIndex < 4 || questionIndex === 5) && answerIndex === 0) {
            showAlert();

             if (isAdvocate)
            {
                navigation.navigate('Advocate');
            }else{
                navigation.navigate('SignUpView');
            }
        }else  if (questionIndex === 5 && answerIndex === 1) {
            //handleHideview(questionIndex+1,true);
            
            if (isAdvocate)
            {
                navigation.navigate('Advocate');
            }else{
                navigation.navigate('SignUpView');
            }
        }
    };
    const questions = [
        {
            question: "Would you prefer to eat lunch with other people at a congregate center or have lunch delivered to your home?",
            options: ['Congregate', 'Home Delivered Meal'],
        },
        {
            question: "Are you able to prepare your own lunch everyday?",
            options: ['Yes', 'No'],
        },
        {
            question: "Do you have reliable support for meal preparation?",
            options: ['Yes', 'No'],
        },
        {
            question: "Do you have a paid caregiver or attendant?",
            options: ['Yes', 'No'],
        },
        {
            question: "Are you available to be home for meal delivery on weekdays? (Meals are typically delivered between 10:30 AM - 1:00 PM)",
            options: ['Yes', 'No'],
        },
        {
            question: "Are you able to drive?",
            options: ['Yes', 'No'],
        },
    ];

    const dropdownOptions = ['Travis', 'Williamson', 'Other'];
    
    const showAlert = () => {
        //console.log(Platform.OS)
        if (Platform.OS === 'web')
        {
            alert('You may not be eligible for our Home Delivered Meal program at this time.');
        }
        else
        {
            Alert.alert(
                'Note',
                'You may not be eligible for our Home Delivered Meal program at this time.',
                [{ text: 'OK', onPress: () => navigation.navigate('SignUpView') }],
                { cancelable: false }
              );
        }
      };

    return (
        <View style={styles.container} >
            <ScrollView showsVerticalScrollIndicator={true}>
                <Image
                    source={require('../../../assets/delivery.jpg')}
                    style={styles.image}
                />
                <Text style = {styles.questionText}>Which county do you live in?</Text>
                <Picker
                    selectedValue={selectedDropdownValue}
                    onValueChange={(itemValue) => handleDropdownChange(itemValue)}
                    style={styles.dropdown}
                >
                    {dropdownOptions.map((option, index) => (
                        <Picker.Item key={index} label={option} value={option} />
                    ))}
                </Picker>
                {questions.map((question, questionIndex) => (
                    <View key={questionIndex} style={showarray[questionIndex] === true ? styles.questionContainer : styles.dropdownContainerhide}>
                        <Text style= {styles.questionText}
                        
                        >{question.question}</Text>
                        {question.options.map((option, optionIndex) => (
                            <TouchableOpacity
                                key={optionIndex}
                                style={[
                                    styles.answerButton,
                                    answers[questionIndex] === optionIndex && styles.selectedAnswer,
                                ]}
                                onPress={() => handleAnswerSelection(questionIndex, optionIndex)}
                            >
                                <Text style={styles.answerText}>{option}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                ))}
            </ScrollView>
        </View>  
      );
  };

  
  

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignSelf: 'center',
      padding: 20,
      width: '100%'
    },
    questionContainer: {
        marginBottom: 20,
        alignSelf: 'center',
       
    },
    questionContainer: {
        marginBottom: 20,
        alignSelf: 'center',
       
    },
    dropdownContainerhide: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
        opacity: 0,
        height: 0,
    },
    dropdownLabel: {
        fontSize: 16,
        marginRight: 10,
        alignSelf: 'center',
    },
    dropdown: {
        width: 150,
        alignSelf: 'center',
    },
    image: {
        width: 200, // Adjust the width and height as needed
        height: 200,
        marginBottom: 20,
        alignSelf: 'center',
    },
    questionText: {
      fontSize: 20,
      marginBottom: 20,
      alignSelf: 'center',
    },
    
    answerButton: {
      borderWidth: 1,
      borderColor: '#ccc',
      borderRadius: 8,
      padding: 10,
      marginVertical: 5,
      width: 200,
      alignSelf: 'center',
    },
    answerText: {
      fontSize: 16,
      alignSelf: 'center',
    },
    selectedAnswer: {
      backgroundColor: 'green', // Change the color to indicate the selected answer
    },
  });
export default KnockOut;