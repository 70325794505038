import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import colors from "../../styles/colors";

const DeliveredPopup = ({ isVisible, onClose, onSelection }) => {
  // State to track the selected item in the delivered popup.
  const [selectedItem, setSelectedItem] = useState(null);

  /**
   * Toggles the selected item in the delivered popup and invokes onSelection
   * callback with the corresponding code.
   */
  const toggleItem = (item) => {
    let code = "null";
    if (selectedItem !== item) {
      code = getCodeForItem(item);
    }
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === item ? null : item
    );
    if (onSelection) {
      onSelection(code);
    }
  };

  /**
   * Maps a checked item to its corresponding delivery code.
   * These are the delivery codes when the user selected "Delivered to..."
   * CLI: Client
   * CAR: Caregiver
   * RES: Resident in home
   * NEI: Neighbor
   * COW: Co-worker
   */
  const getCodeForItem = (item) => {
    switch (item) {
      case "client":
        return "CLI";
      case "caregiver":
        return "CAR";
      case "resident in home":
        return "RES";
      case "neighbor":
        return "NEI";
      case "co-worker":
        return "COW";
    }
  };

  return (
    isVisible && (
      <View style={styles.popupContainer}>
        <View style={styles.popup}>
          <Text style={styles.header}>Delivered to...</Text>
          <View style={styles.checklist}>
            {[
              "client",
              "caregiver",
              "resident in home",
              "neighbor",
              "co-worker",
              "refrigerator",
            ].map((item) => (
              <TouchableOpacity
                key={item}
                style={styles.checkboxItem}
                onPress={() => toggleItem(item)}
              >
                <View
                  style={[
                    styles.checkbox,
                    selectedItem === item && styles.checkedBox,
                  ]}
                >
                  {selectedItem === item && (
                    <Text style={styles.checkmark}>✓</Text>
                  )}
                </View>
                <Text style={styles.checkboxText}>{item}</Text>
              </TouchableOpacity>
            ))}
          </View>
          <TouchableOpacity
            onPress={() => onClose()}
            style={styles.submitButton}
          >
            <Text style={styles.submitButtonText}>Submit</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  );
};

/**
 * Style sheet.
 */
const styles = StyleSheet.create({
  popupContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    alignItems: "center",
    justifyContent: "center",
  },
  popup: {
    width: "50%",
    backgroundColor: "white",
    borderRadius: 10,
    padding: "6%",
  },
  header: {
    color: "#000",
    fontFamily: "Inter-Medium",
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: 1.2,
  },
  closeButton: {
    position: "absolute",
    top: "2%",
    right: "4%",
  },
  closeButtonText: {
    fontSize: 24,
    color: "#000",
  },
  checklist: {
    marginTop: "8%",
  },
  checkboxItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5%",
  },
  checkbox: {
    aspectRatio: 1,
    width: "7%",
    height: "80%",
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 3,
    marginRight: "5%",
    justifyContent: "center",
    alignItems: "center",
  },
  checkedBox: {
    backgroundColor: "#C4D82E",
  },
  checkmark: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
  },
  checkboxText: {
    color: "#000",
    // fontFamily: 'Inter-Regular',
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: 1,
  },
  submitButton: {
    backgroundColor: colors.primaryColorVariant, // Customize the button background color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 15,
  },
  submitButtonText: {
    color: "white", // Customize the button text color
    fontSize: 20,
    textAlign: "center",
  },
});

export default DeliveredPopup;
