import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  Platform,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MealSelection from "./MealSelection";
import CustomHeader from "../../../shared/CustomHeader";
import colors from "../../../styles/colors";
import BottomNavigationBar from "../../../shared/BottomNavigationBar";

const WeekButton = ({ weekNumber, onPress, isMobile }) => (
  <TouchableOpacity
    style={[
      styles.weekButton,
      isMobile && styles.mobileWeekButton, // Apply mobile style if isMobile is true
    ]}
    onPress={() => onPress(weekNumber)}
  >
    <Text style={styles.weekButtonText}>Week {weekNumber}</Text>
  </TouchableOpacity>
);

const WeekSelection = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigation = useNavigation();
  const [selectedWeek, setSelectedWeek] = useState("WEEK1"); // Initialize with a default week

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, []);

  useEffect(() => {
    // Check if the screen width is less than a certain threshold (e.g., 600)
    const screenWidth = Dimensions.get("window").width;
    setIsMobile(screenWidth < 600); // Adjust the threshold as needed
  }, []);

  const handleWeekButtonClick = (weekNumber) => {
    setSelectedWeek(`WEEK${weekNumber}`); // Update selectedWeek based on the button click
    console.log(selectedWeek);
    navigation.navigate("MealSelection", {
      selectedWeek: `WEEK${weekNumber}`, // Pass the selected week to the next screen
    });
    console.log(`Button for Week ${weekNumber} clicked`);
  };

  const isWeb = () => Platform.OS === "web";

  // Render custom header only on web UI
  const renderCustomHeader = isWeb() ? <CustomHeader /> : null;
  const renderBottomNavigation = isWeb() ? null : <BottomNavigationBar />;

  return (
    <View style={{ flex: 1 }}>
      {renderCustomHeader}
      <View style={styles.container}>
        <View style={[isWeb() && styles.card]}>
          <Text style={styles.header}>Select a Week</Text>
          <View
            style={[
              styles.buttonContainer,
              isMobile ? styles.verticalContainer : styles.horizontalContainer,
            ]}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8].map((weekNumber) => (
              <WeekButton
                key={weekNumber}
                weekNumber={weekNumber}
                onPress={handleWeekButtonClick}
                isMobile={isMobile} // Pass the isMobile flag to the WeekButton component
              />
            ))}
          </View>
        </View>
      </View>
      {renderBottomNavigation}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center", // Center vertically
    alignItems: "center", // Center horizontally
    backgroundColor: "white",
  },
  card: {
    backgroundColor: "white",
    borderRadius: 8,
    elevation: 5,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    margin: 10,
    padding: 20,
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: "center", // Center-align the text
  },
  buttonContainer: {
    justifyContent: "center",
    flexWrap: "wrap",
  },
  verticalContainer: {
    flexDirection: "column",
    justifyContent: "center", // Center vertically
    alignItems: "center", // Center horizontally
    alignSelf: "center",
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  weekButton: {
    backgroundColor: colors.primaryColor,
    padding: 10,
    margin: 10,
    borderRadius: 8,
  },
  weekButtonText: {
    color: "white",
    fontSize: 16,
  },
  mobileWeekButton: {
    width: 170, // Adjust the width as per your requirements for mobile
    height: 60,
    justifyContent: "center", // Center the content vertically
    alignItems: "center", // Center the content horizontally
  },
});

export default WeekSelection;
